export const BRAND_QUIZ_SKIP_QUESTION = 'BRAND_QUIZ_SKIP_QUESTION';
export const BRAND_QUIZ_SELECT_CHOICE = 'BRAND_QUIZ_SELECT_CHOICE';
export const BRAND_QUIZ_CHECK_STATE = 'BRAND_QUIZ_CHECK_STATE';
export const BRAND_QUIZ_CLUE = 'BRAND_QUIZ_CLUE';
export const BRAND_QUIZ_REDRAW = 'BRAND_QUIZ_REDRAW';

export const EMOJI_PUZZLE_SKIP_QUESTION = 'EMOJI_PUZZLE_SKIP_QUESTION';
export const EMOJI_PUZZLE_CLUE = 'EMOJI_PUZZLE_CLUE';
export const EMOJI_PUZZLE_SELECT_CHOICE = 'EMOJI_PUZZLE_SELECT_CHOICE';
export const EMOJI_PUZZLE_CHECK_LINK = 'EMOJI_PUZZLE_CHECK_LINK';
export const EMOJI_PUZZLE_CHECK_STATE = 'EMOJI_PUZZLE_CHECK_STATE';

export const HIDDEN_CHARACTER_REVEAL = 'HIDDEN_CHARACTER_REVEAL';
export const HIDDEN_CHARACTER_TRY_ANSWER = 'HIDDEN_CHARACTER_TRY_ANSWER';
export const HIDDEN_CHARACTER_SKIP_QUESTION = 'HIDDEN_CHARACTER_SKIP_QUESTION';
export const HIDDEN_CHARACTER_REDRAW = 'HIDDEN_CHARACTER_REDRAW';

export const MISSING_WORDS_ADD_CHOICE = 'MISSING_WORDS_ADD_CHOICE';
export const MISSING_WORDS_SKIP_QUESTION = 'MISSING_WORDS_SKIP_QUESTION';
export const MISSING_WORDS_CHECK_STATE = 'MISSING_WORDS_CHECK_STATE';
export const MISSING_WORDS_CLUE = 'MISSING_WORDS_CLUE';
export const MISSING_WORDS_REDRAW = 'MISSING_WORDS_REDRAW';

export const THE_LIST_PROPOSITION = 'THE_LIST_PROPOSITION';
export const THE_LIST_CLUE = 'THE_LIST_CLUE';
export const THE_LIST_VALIDATION = 'THE_LIST_VALIDATION';
export const THE_LIST_SKIP_QUESTION = 'THE_LIST_SKIP_QUESTION';
export const THE_LIST_CHECK_STATE = 'THE_LIST_CHECK_STATE';

export const TRUE_FALSE_TRUE = 'TRUE_FALSE_TRUE';
export const TRUE_FALSE_FALSE = 'TRUE_FALSE_FALSE';
export const TRUE_FALSE_SKIP_QUESTION = 'TRUE_FALSE_SKIP_QUESTION';
export const TRUE_FALSE_CLUE = 'TRUE_FALSE_CLUE';

export const ADD_THE_LIST_QUESTIONS = 'ADD_THE_LIST_QUESTIONS';
export const ADD_EMOJI_PUZZLE_QUESTIONS = 'ADD_EMOJI_PUZZLE_QUESTIONS';
export const ADD_HIDDEN_CHARACTER_QUESTIONS = 'ADD_HIDDEN_CHARACTER_QUESTIONS';
export const ADD_MISSING_WORDS_QUESTIONS = 'ADD_MISSING_WORDS_QUESTIONS';
export const ADD_TRUE_FALSE_QUESTIONS = 'ADD_TRUE_FALSE_QUESTIONS';
export const ADD_BRAND_QUIZ_QUESTIONS = 'ADD_BRAND_QUIZ_QUESTIONS';