import React from "react";
import "./YourExperienceView.css";
import {ViewHeader} from "../View/ViewHeader";
import {ViewTitleBar} from "../View/ViewTitleBar";
import {ViewContent} from "../View/ViewContent";
import {TestButton} from "../Buttons/TestButton";
import {Thumbnail} from "../Thumbnail";

export function YourExperienceView() {
    return (
        <div className={"View"} id={"View__YourExperience"}>
            <ViewHeader>
                <ViewTitleBar title={"Votre expérience"} />
            </ViewHeader>

            <ViewContent>
                <Thumbnail title={"Solo"} />
                <Thumbnail title={"Equipe"} />

                <TestButton content={"Valider"} />
            </ViewContent>
        </div>
    );
}