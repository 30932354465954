import React, {ReactNode} from "react";
import "./Clue.css";

import clueIcon from "../../Assets/indice.svg";

interface ClueProps {
    children: ReactNode
}

export function Clue ({children}: ClueProps) {
    return (
        <div className={"Clue"}>
            <img className={"Clue__Icon"} src={clueIcon} alt={"Icon d'indice"} />
            <div className={"Clue__Content"}>{children}</div>
        </div>
    )
}