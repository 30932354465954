import "./Reward.css";
import {ProgressBar} from "./ProgressBar/ProgressBar";
import React from "react";
import RewardIcon from "../Assets/gift-box.svg";

interface RewardProps {
    value: number;
}

export function Reward ({value}: RewardProps) {
    return (
        <div className="Reward">
            <div className="Reward__Preview">
                <img draggable={"false"} className={"Reward__Icon"} src={RewardIcon} alt={"Reward icon"} />
            </div>

            <div className="Reward__Content">
                <div className="Reward__Label">
                    Votre récompense :
                </div>

                <ProgressBar value={Math.round(value)} isLarge={true} />
            </div>
        </div>
    );
}