import React from "react";
import "./TotalScore.css";
import {TweenMax} from "gsap";
import {store} from "../store";
import {ADD_ON_TOTAL_ACTION, REMOVE_SCORE_ACTION} from "../store/userReducer";

interface TotalScoreInterface {
    score: number;
    total: number;
    type: string;
}

export function TotalScore ({score, type, total}: TotalScoreInterface) {
    const scoreAnimation = () => {
        let points = score + total;
        let userTotal = {val: total};
        let zeroToScore = {val: 0};

        // Ajouter
        TweenMax.to(userTotal, 1.5, {
            val: points,
            roundProps: "val",
            delay: 0.65,
            onUpdate: () => {
                let elem = document.querySelector('.TotalScore__Total');
                if (elem) elem.innerHTML = "Score total : " + Math.round(userTotal.val) + " pts";
            }
        });

        // Ajouter au total
        TweenMax.to(zeroToScore, 1.5, {
            val: score,
            roundProps: "val",
            delay: 0.5,
            onUpdate: () => {
                let elem = document.querySelector('.TotalScore__Score');
                if (elem) elem.innerHTML = Math.round(zeroToScore.val) + " pts";
            },
            onComplete: () => {
                store.dispatch({type: ADD_ON_TOTAL_ACTION, payload: {type: type}});
                store.dispatch({type: REMOVE_SCORE_ACTION, payload: {type: type, dont: true}});
                let el = document.querySelector('.hidden');
                if (el) el.classList.remove('hidden');
            }
        });
    }

    scoreAnimation();

    return (
        <div className={"TotalScore"}>
            <div className="TotalScore__Score">{score} pts</div>
            <div className="TotalScore__Total">Score total : {total} <strong>pts</strong></div>
        </div>
    );
}
