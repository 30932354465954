import React from "react";
import {Score} from "../Score";
import {Card} from "../Card/Card";
import {CardPreview} from "../Card/CardPreview";
import {CardContent} from "../Card/CardContent";
import {CardTitle} from "../Card/CardTitle";
import {ProgressBar} from "../ProgressBar/ProgressBar";
import {ButtonLink} from "../Buttons/ButtonLink";
import {Reward} from "../Reward";

import TheList from "../../Assets/game/La liste.svg";
import HiddenCharacter from "../../Assets/game/Personnage caché.svg";
import EmojiPuzzle from "../../Assets/game/emoji puzzle.svg";
import MissingWords from "../../Assets/game/Mots manquants.svg";
import TrueFalse from "../../Assets/game/vrai ou faux.svg";
import Quiz from "../../Assets/game/quiz.svg"

import { motion } from "framer-motion";
import {useSelector} from "react-redux";
import {selectUser} from "../../store/userReducer";
import {
    currentTrueFalse,
    selectTrueFalsePercent,
    totalQuestionsTrueFalse,
    trueFalseTotal
} from "../../store/trueFalseReducer";
import {
    currentMissingWords,
    missingWordsTotal,
    selectMissingWordsPercent,
    totalQuestionsMissingWords
} from "../../store/missingWordsReduce";
import {
    currentHiddenCharacter,
    hiddenCharacterTotal,
    selectHiddenCharacterPercent, totalQuestionsHiddenCharacter
} from "../../store/hiddenCharacterReducer";
import {
    brandQuizTotal,
    currentBrandQuiz,
    selectBrandQuizPercent,
    totalQuestionsBrandQuiz
} from "../../store/brandQuizReducer";
import {
    currentEmojiPuzzle,
    emojiPuzzleTotal,
    selectEmojiPuzzlePercent,
    totalQuestionsEmojiPuzzle
} from "../../store/emojiPuzzleReducer";
import {
    currentTheList,
    selectTheListPercent,
    theListTotal,
    totalQuestionsTheList
} from "../../store/theListReducer";
import {EndView} from "./EndView";
import close from "../../Assets/close.svg";
import {Link} from "react-router-dom";
import {EndedProof} from "../EndedProof";
import gsap from "gsap";
import {AvatarPopup} from "../Popup/AvatarPopup";

export function HomeView () {
    const user = useSelector(selectUser);

    const trueFalsePercent = useSelector(selectTrueFalsePercent);
    const missingWordsPercent = useSelector(selectMissingWordsPercent);
    const hiddenCharacterPercent = useSelector(selectHiddenCharacterPercent);
    const brandQuizPercent = useSelector(selectBrandQuizPercent);
    const emojiPuzzlePercent = useSelector(selectEmojiPuzzlePercent);
    const theListPercent = useSelector(selectTheListPercent);

    let nbGames = 0;
    if (totalQuestionsTheList() > 0) nbGames++;
    if (totalQuestionsBrandQuiz() > 0) nbGames++;
    if (totalQuestionsEmojiPuzzle() > 0) nbGames++;
    if (totalQuestionsMissingWords() > 0) nbGames++;
    if (totalQuestionsTrueFalse() > 0) nbGames++;
    if (totalQuestionsHiddenCharacter() > 0) nbGames++;

    const totalScore = trueFalseTotal() + missingWordsTotal() + hiddenCharacterTotal() + brandQuizTotal() + emojiPuzzleTotal() + theListTotal();
    const totalPercent = (trueFalsePercent + missingWordsPercent + hiddenCharacterPercent + brandQuizPercent + emojiPuzzlePercent + theListPercent) / nbGames;

    if (totalPercent >= 100)
    {
        return <EndView />
    }

    return (
        <motion.div className={"View"} id={"View__home"} initial={{ opacity: 0, right: -200, top: 0 }} animate={{ opacity: 1, right: 0, top: 0 }} exit={{ opacity: 0, right: -200, top: 0 }} transition={{ duration: 0.4 }}>
            <div className="App">
                <header className="App-header">
                    <div style={{width: "100%"}} className={"View__title-bar"}>
                        <Link className={"hamburger-link"} to={"/profile"}>
                            <img src={close} alt={"Go back"} />
                        </Link>

                        <div className="Header-title">
                            Les épreuves
                        </div>
                    </div>

                    <div className={"Score__Section"}>
                        <img onClick={avatarClicked} draggable={"false"} src={user.avatar} alt={"Test"} className={"View__Profile-avatar"} />
                        <div className="View__Profile-information-username">{user.username}</div>
                    </div>
                    <Score value={user.total} max={totalScore} />

                    <div className="Header-description">
                        Complétez les différentes épreuves et tentez de gagner des cadeaux !
                    </div>
                </header>

                <section className="Section">
                    <div className="mask" />

                    <div className="cards">
                        {
                            totalQuestionsMissingWords() > 0 ?
                                <Card>
                                    <CardPreview svg={MissingWords} />

                                    <CardContent>
                                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "flex-end", marginBottom: "12px"}} className={"Card__Line"}>
                                            <CardTitle title="Mots manquants" />
                                            <span style={{fontSize: "12px"}} className="Card__Question-state">{currentMissingWords}/{totalQuestionsMissingWords()}</span>
                                        </div>

                                        <ProgressBar value={missingWordsPercent} />

                                        {missingWordsPercent === 100 ? <EndedProof /> : <ButtonLink link={"/missing-words"} content="Jouer" /> }
                                    </CardContent>
                                </Card>
                            : ''
                        }

                        {
                            totalQuestionsHiddenCharacter() > 0 ?
                                <Card>
                                    <CardPreview svg={HiddenCharacter} />

                                    <CardContent>
                                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "flex-end", marginBottom: "12px"}} className={"Card__Line"}>
                                            <CardTitle title="Personnage caché" />
                                            <span style={{fontSize: "12px"}} className="Card__Question-state">{currentHiddenCharacter}/{totalQuestionsHiddenCharacter()}</span>
                                        </div>
                                        <ProgressBar value={hiddenCharacterPercent} />

                                        {hiddenCharacterPercent === 100 ? <EndedProof /> : <ButtonLink link={"/hidden-character"} content="Jouer" /> }
                                    </CardContent>
                                </Card>
                            : ''
                        }

                        {
                            totalQuestionsTheList() > 0 ?
                                <Card>
                                    <CardPreview svg={TheList} />

                                    <CardContent>
                                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "flex-end", marginBottom: "12px"}} className={"Card__Line"}>
                                            <CardTitle title="La liste" />
                                            <span style={{fontSize: "12px"}} className="Card__Question-state">{currentTheList}/{totalQuestionsTheList()}</span>
                                        </div>
                                        <ProgressBar value={theListPercent} />

                                        {theListPercent === 100 ? <EndedProof /> : <ButtonLink link={"/the-list"} content="Jouer" /> }
                                    </CardContent>
                                </Card>
                            : ''
                        }

                        {
                            totalQuestionsTrueFalse() > 0 ?
                                <Card>
                                    <CardPreview svg={TrueFalse} />

                                    <CardContent>
                                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "flex-end", marginBottom: "12px"}} className={"Card__Line"}>
                                            <CardTitle title="Vrai ou Faux" />
                                            <span style={{fontSize: "12px"}} className="Card__Question-state">{currentTrueFalse}/{totalQuestionsTrueFalse()}</span>
                                        </div>
                                        <ProgressBar value={trueFalsePercent} />

                                        {trueFalsePercent === 100 ? <EndedProof /> : <ButtonLink link={"/true-false"} content="Jouer" /> }
                                    </CardContent>
                                </Card>
                            : ''
                        }

                        {
                            totalQuestionsBrandQuiz() ?
                                <Card>
                                    <CardPreview svg={Quiz} />

                                    <CardContent>
                                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "flex-end", marginBottom: "12px"}} className={"Card__Line"}>
                                            <CardTitle title="Quiz de la marque" />
                                            <span style={{fontSize: "12px"}} className="Card__Question-state">{currentBrandQuiz}/{totalQuestionsBrandQuiz()}</span>
                                        </div>
                                        <ProgressBar value={brandQuizPercent} />
                                        {brandQuizPercent === 100 ? <EndedProof /> : <ButtonLink link={"/brand-quiz"} content="Jouer" /> }
                                    </CardContent>
                                </Card>
                            : ''
                        }


                        {
                            totalQuestionsEmojiPuzzle() > 0 ?
                                <Card>
                                    <CardPreview svg={EmojiPuzzle} />

                                    <CardContent>
                                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "flex-end", marginBottom: "12px"}} className={"Card__Line"}>
                                            <CardTitle title="Emoji Puzzle" />
                                            <span style={{fontSize: "12px"}} className="Card__Question-state">{currentEmojiPuzzle}/{totalQuestionsEmojiPuzzle()}</span>
                                        </div>
                                        <ProgressBar value={emojiPuzzlePercent} />
                                        {emojiPuzzlePercent === 100 ? <EndedProof /> : <ButtonLink link={"/emoji-puzzle"} content="Jouer" /> }
                                    </CardContent>
                                </Card>
                            : ''
                        }
                    </div>

                    <Reward value={totalPercent} />

                    <AvatarPopup />
                </section>
            </div>
        </motion.div>
    );
}

function avatarClicked () {
    let element = document.querySelector('.Popup__large');
    if (element) element.classList.remove('none');
    gsap.to('.Popup__large > .Popup__Mask', {
        y: 0,
        duration: 0.5
    });
    gsap.to('.Popup__large', {
        opacity: 1,
        duration: 0.5
    });
}
