import React, {ReactNode} from "react";
import "./Line.css";

interface LineProps {
    children: ReactNode;
}

export function Line ({children}: LineProps) {
    return (
        <div className={"Text__Line"}>
            { children }
        </div>
    );
}