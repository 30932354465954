import { motion } from "framer-motion";
import React from "react";
import "./AvatarPopup.css";
import {Popup} from "./Popup";
import {PopupTitle} from "./PopupTitle";
import {TestButton} from "../Buttons/TestButton";
import memoji01 from "../../Assets/Avatars/Memoji-01.png";
import memoji02 from "../../Assets/Avatars/Memoji-02.png";
import memoji03 from "../../Assets/Avatars/Memoji-03.png";
import memoji04 from "../../Assets/Avatars/Memoji-04.png";
import memoji05 from "../../Assets/Avatars/Memoji-05.png";
import memoji06 from "../../Assets/Avatars/Memoji-06.png";
import memoji07 from "../../Assets/Avatars/Memoji-07.png";
import memoji08 from "../../Assets/Avatars/Memoji-08.png";
import memoji09 from "../../Assets/Avatars/Memoji-09.png";
import memoji10 from "../../Assets/Avatars/Memoji-10.png";
import memoji11 from "../../Assets/Avatars/Memoji-11.png";
import memoji12 from "../../Assets/Avatars/Memoji-12.png";
import memoji13 from "../../Assets/Avatars/Memoji-13.png";
import memoji14 from "../../Assets/Avatars/Memoji-14.png";
import memoji15 from "../../Assets/Avatars/Memoji-15.png";
import memoji16 from "../../Assets/Avatars/Memoji-16.png";
import memoji17 from "../../Assets/Avatars/Memoji-17.png";
import memoji18 from "../../Assets/Avatars/Memoji-18.png";
import memoji19 from "../../Assets/Avatars/Memoji-19.png";
import memoji20 from "../../Assets/Avatars/Memoji-20.png";
import gsap from "gsap";
import {selectUser, UPDATE_MEMOJI_ACTION} from "../../store/userReducer";
import {store} from "../../store";
import {useSelector} from "react-redux";

let selectedImage: any;

export function AvatarPopup () {
    const user = useSelector(selectUser);
    selectedImage = user.avatar;

    return (
        <motion.div>
            <Popup none large>
                <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", alignItems: "center" }}>

                    <PopupTitle colored title={"Choisissez votre avatar"} />

                    <div className={"Avatar__list"}>
                        <img src={memoji01} style={memoji01 === selectedImage ? {borderColor: "#fdac4c"} : {}} onClick={() => {selectedImage = memoji01; updateAvatar()}} alt={"Memoji 01"} className={"Avatar"} />
                        <img src={memoji02} style={memoji02 === selectedImage ? {borderColor: "#fdac4c"} : {}} onClick={() => {selectedImage = memoji02; updateAvatar()}} alt={"Memoji 02"} className={"Avatar"} />
                        <img src={memoji03} style={memoji03 === selectedImage ? {borderColor: "#fdac4c"} : {}} onClick={() => {selectedImage = memoji03; updateAvatar()}} alt={"Memoji 03"} className={"Avatar"} />
                        <img src={memoji04} style={memoji04 === selectedImage ? {borderColor: "#fdac4c"} : {}} onClick={() => {selectedImage = memoji04; updateAvatar()}} alt={"Memoji 04"} className={"Avatar"} />

                        <img src={memoji05} style={memoji05 === selectedImage ? {borderColor: "#fdac4c"} : {}} onClick={() => {selectedImage = memoji05; updateAvatar()}} alt={"Memoji 05"} className={"Avatar"} />
                        <img src={memoji06} style={memoji06 === selectedImage ? {borderColor: "#fdac4c"} : {}} onClick={() => {selectedImage = memoji06; updateAvatar()}} alt={"Memoji 06"} className={"Avatar"} />
                        <img src={memoji07} style={memoji07 === selectedImage ? {borderColor: "#fdac4c"} : {}} onClick={() => {selectedImage = memoji07; updateAvatar()}} alt={"Memoji 07"} className={"Avatar"} />
                        <img src={memoji08} style={memoji08 === selectedImage ? {borderColor: "#fdac4c"} : {}} onClick={() => {selectedImage = memoji08; updateAvatar()}} alt={"Memoji 08"} className={"Avatar"} />

                        <img src={memoji09} style={memoji09 === selectedImage ? {borderColor: "#fdac4c"} : {}} onClick={() => {selectedImage = memoji09; updateAvatar()}} alt={"Memoji 09"} className={"Avatar"} />
                        <img src={memoji10} style={memoji10 === selectedImage ? {borderColor: "#fdac4c"} : {}} onClick={() => {selectedImage = memoji10; updateAvatar()}} alt={"Memoji 10"} className={"Avatar"} />
                        <img src={memoji11} style={memoji11 === selectedImage ? {borderColor: "#fdac4c"} : {}} onClick={() => {selectedImage = memoji11; updateAvatar()}} alt={"Memoji 11"} className={"Avatar"} />
                        <img src={memoji12} style={memoji12 === selectedImage ? {borderColor: "#fdac4c"} : {}} onClick={() => {selectedImage = memoji12; updateAvatar()}} alt={"Memoji 12"} className={"Avatar"} />

                        <img src={memoji13} style={memoji13 === selectedImage ? {borderColor: "#fdac4c"} : {}} onClick={() => {selectedImage = memoji13; updateAvatar()}} alt={"Memoji 13"} className={"Avatar"} />
                        <img src={memoji14} style={memoji14 === selectedImage ? {borderColor: "#fdac4c"} : {}} onClick={() => {selectedImage = memoji14; updateAvatar()}} alt={"Memoji 14"} className={"Avatar"} />
                        <img src={memoji15} style={memoji15 === selectedImage ? {borderColor: "#fdac4c"} : {}} onClick={() => {selectedImage = memoji15; updateAvatar()}} alt={"Memoji 15"} className={"Avatar"} />
                        <img src={memoji16} style={memoji16 === selectedImage ? {borderColor: "#fdac4c"} : {}} onClick={() => {selectedImage = memoji16; updateAvatar()}} alt={"Memoji 16"} className={"Avatar"} />

                        <img src={memoji17} style={memoji17 === selectedImage ? {borderColor: "#fdac4c"} : {}} onClick={() => {selectedImage = memoji17; updateAvatar()}} alt={"Memoji 17"} className={"Avatar"} />
                        <img src={memoji18} style={memoji18 === selectedImage ? {borderColor: "#fdac4c"} : {}} onClick={() => {selectedImage = memoji18; updateAvatar()}} alt={"Memoji 18"} className={"Avatar"} />
                        <img src={memoji19} style={memoji19 === selectedImage ? {borderColor: "#fdac4c"} : {}} onClick={() => {selectedImage = memoji19; updateAvatar()}} alt={"Memoji 19"} className={"Avatar"} />
                        <img src={memoji20} style={memoji20 === selectedImage ? {borderColor: "#fdac4c"} : {}} onClick={() => {selectedImage = memoji20; updateAvatar()}} alt={"Memoji 20"} className={"Avatar"} />
                    </div>

                    <TestButton onClick={avatarPopupButtonClicked} content={"Choisir"} type={"is-large"} />
                </div>
            </Popup>
        </motion.div>
    );
}

function updateAvatar() {
    store.dispatch({type: UPDATE_MEMOJI_ACTION, payload: selectedImage });
}

function avatarPopupButtonClicked () {
    gsap.to('.Popup__large > .Popup__Mask', {
        y: 1000,
        duration: 0.5
    });
    gsap.to('.Popup__large', {
        opacity: 0,
        duration: 0.5
    });
    setTimeout(() => {
        let element = document.querySelector('.Popup__large');
        if (element) element.classList.add('none');
    }, 500);
}

window.addEventListener('DOMContentLoaded', () => {
    avatarPopupButtonClicked();
});