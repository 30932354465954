import {MissingWordsState} from "./index";
import {
    ADD_MISSING_WORDS_QUESTIONS,
    MISSING_WORDS_ADD_CHOICE,
    MISSING_WORDS_CHECK_STATE,
    MISSING_WORDS_CLUE, MISSING_WORDS_REDRAW,
    MISSING_WORDS_SKIP_QUESTION
} from "./actionsList";
import {shuffle} from "./emojiPuzzleReducer";

let questions: MissingWordsState[] = [];

export let missingWordsAnswers = {
    good: 0,
    wrong: 0,
    wrongsOnQuestion: 0
}

const initialState: MissingWordsState = questions[0];
export let currentMissingWords: number = 0;
export const totalQuestionsMissingWords = () => questions.length;

export const selectQuestion = (state: any) => state.missingWords;
export const selectMissingWordsPercent = (state: any) => (currentMissingWords / totalQuestionsMissingWords() * 100) || 0;

export function missingWordsReducer (state = initialState, action: any) {
    switch (action.type) {
        case MISSING_WORDS_ADD_CHOICE:
            for (let i = 0; i < state.responses.length; i++) if (state.responses[i] === action.payload.choice) {
                state.goods[i] = state.responses[i];
                missingWordsAnswers.good++;

                return {
                    ...state
                };
            }

            missingWordsAnswers.wrongsOnQuestion++;
            missingWordsAnswers.wrong++;
            state.wrongs = [...state.wrongs, action.payload.choice];
            if (noClues(state)) {
                state.cluesUsed = true;
            }
            if (!alreadyWrong(state, action.payload.choice)) {
                return {
                    ...state,
                };
            }
            return {
                ...state
            };
        // TODO : Améliorer pour pas que ça change de question après avoir eu faux toutes les réponses / ou alors corriger l'animation pour la transition vers la prochaine question
        case MISSING_WORDS_CHECK_STATE:
            let allFound = true;
            for (let i = 0; i < state.responses.length; i++) if (state.responses[i] !== state.goods[i]) allFound = false;
            if (allFound || state.goods.length + state.wrongs.length >= state.choices.length)
            {
                currentMissingWords++;
                missingWordsAnswers.wrongsOnQuestion = 0;
                if (questions.length > currentMissingWords) return questions[currentMissingWords];
            }

            return {
                ...state
            }
        case MISSING_WORDS_CLUE:
            if (state.disabled.length >= state.choices.length - state.responses.length) return state;

            let currents = [];
            for (let i = 0; i < state.choices.length; i++) {
                if (!alreadyDisabled(state, state.choices[i]) && !isResponse(state.choices[i]) && !alreadyWrong(state, state.choices[i])) currents.push(i);
            }

            if (currents.length === 0) {
                state.cluesUsed = true;
                return {...state};
            }

            let random = Math.floor(Math.random() * currents.length);
            state.disabled = [...state.disabled, state.choices[currents[random]]];

            if (noClues(state)) {
                state.cluesUsed = true;
            }

            return {
                ...state,
            }
        case MISSING_WORDS_SKIP_QUESTION:
            currentMissingWords++;
            missingWordsAnswers.wrongsOnQuestion = 0;
            if (questions.length > currentMissingWords) return questions[currentMissingWords];

            return {...state};
        case MISSING_WORDS_REDRAW:
            return {...state};
        case ADD_MISSING_WORDS_QUESTIONS:
            if (action.payload && Array.isArray(action.payload))
            {
                let arr: MissingWordsState[] = [];
                action.payload.forEach((question: any) => {
                    let lines = question.lines.split('\n');
                    for (let i = 0; i < lines.length; i++) lines[i] = lines[i].replace('#', '_');

                    let responses = question.goods.split(';');
                    let goods = [];
                    for (let i = 0; i < responses.length; i++) {
                        if (responses[i].charAt(0) === ' ') responses[i] = responses[i].substr(1);
                        goods.push("");
                    }

                    let wrongs = question.wrongs.split(';');
                    for (let i = 0; i < wrongs.length; i++) if (wrongs[i].charAt(0) === ' ') wrongs[i] = wrongs[i].substr(1);

                    for (let i = 0; i < goods.length; i++) if (goods[i] === "") goods.splice(i, 1);
                    for (let i = 0; i < wrongs.length; i++) if (wrongs[i] === "") wrongs.splice(i, 1);

                    let q: MissingWordsState = {
                        id: question.id,
                        statement: question.statement,
                        lines: lines,
                        choices: shuffle([...responses, ...wrongs]),
                        responses: responses,
                        goods: goods,
                        disabled: [],
                        wrongs: [],
                        points: 20,
                        cluesUsed: false
                    }

                    arr.push(q);
                });
                questions = arr;
            }
            return questions[0] || null;
        default:
            return state || null;
    }
}

function isResponse (choice: string) {
    for (let i = 0; i < questions[currentMissingWords].responses.length; i++) {
        if (choice === questions[currentMissingWords].responses[i]) return true;
    }
    return false;
}

function noClues (state: MissingWordsState) {
    return state.disabled.length + state.wrongs.length + state.responses.length === state.choices.length;
}

function alreadyDisabled (state: MissingWordsState, choice: string) {
    for (let i = 0; i < state.disabled.length; i++) {
        if (choice === state.disabled[i]) return true;
    }
    return false;
}

function alreadyWrong (state: MissingWordsState, choice: string) {
    for (let i = 0; i < state.wrongs.length; i++) {
        if (choice === state.wrongs[i]) return true;
    }
    return false;
}

export function missingWordsTotal () {
    let total = 0;
    for (let i = 0; i < questions.length; i++) {
        total += questions[i].points * questions[i].responses.length;
    }

    return total;
}
