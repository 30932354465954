import trueAnimationData from "../Assets/test3.json";
import falseAnimationData from "../Assets/test2.json";
import gsap from "gsap";

// User constants
export const GET_USER = 'GET_USER';
export const GET_USER_POSITION = 'GET_USER_POSITION';
export const ADD_SCORE = 'ADD_SCORE';
export const SET_MEMOJI = 'SET_MEMOJI';

// Quiz constants
export const GET_NEXT_TRUE_FALSE = 'GET_NEXT_TRUE_FALSE';
export const GET_NEXT_MISSING_WORDS = 'GET_NEXT_MISSING_WORDS';
export const GET_NEXT_HIDDEN_CHARACTER = 'GET_NEXT_HIDDEN_CHARACTER';
export const GET_NEXT_THE_LIST = 'GET_NEXT_THE_LIST';
export const GET_NEXT_BRAND_QUIZ = 'GET_NEXT_BRAND_QUIZ';
export const GET_NEXT_EMOJI_PUZZLE = 'GET_NEXT_EMOJI_PUZZLE';

export const trueDefaultOptions = {
    loop: false,
    autoplay: true,
    animationData: trueAnimationData,
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
    }
}

export const falseDefaultOptions = {
    loop: false,
    autoplay: true,
    animationData: falseAnimationData,
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
    }
}

export const gsapAnimationsFrom = () => {
    if (document.querySelector('.Statement')) {
        gsap.to('.Statement', {
            duration: 0.2,
            x: -20,
            ease: 'linear',
            opacity: 0
        });
    }
    gsap.to('.Text, .Choices, .true, .false, .help, .answers, .EmojiList, .TextField, .TheList__Words', {
        opacity: 0,
        duration: 0.3
    })
}

export const gsapAnimationsTo = () => {
    if (document.querySelector('.Statement')) {
        gsap.fromTo('.Statement', {
            delay: 0.2,
        }, {
            x: 0,
            delay: 0.2,
            ease: 'linear',
            duration: 0.3,
            opacity: 1
        });
    }
    gsap.to('.Text, .Choices, .answers, .EmojiList, .true, .false, .help, .TextField, .TheList__Words', {
        opacity: 1,
        duration: 0.3,
        delay: 0.4
    });
    if (document.querySelector('.Button')) {
        gsap.to('.Button', {
            duration: 1,
            delay: 0.3,
            opacity: 1
        });
    }
}

export const questionBump = () => {
    gsap.to('.Question__current', {
        y: -12,
        duration: 0.4
    });

    gsap.to('.Question__current', {
        y: 0,
        delay: 0.2,
        duration: 0.4
    });
}
