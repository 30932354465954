import {motion} from "framer-motion";
import React from "react";
import "./experience_shared.css";

import {useHistory} from "react-router-dom";
import {ViewHeader} from "../../View/ViewHeader";
import {Card} from "../../ExperienceCard/Card";
import {CardPreview} from "../../ExperienceCard/CardPreview";
import {CardContent} from "../../ExperienceCard/CardContent";
import {Button} from "../../Buttons/Button";

import "../../../Assets/close.svg";
import adult from "../../../Assets/Adulte.svg";
import family from "../../../Assets/Famille.svg";
import children from "../../../Assets/Enfant.svg";
import {store} from "../../../store";
import {UPDATE_ACCOUNT_ACTION, ACCOUNT_TYPES} from "../../../store/userReducer";

export function ExperienceView (){

    const [choice, setChoice] = React.useState(ACCOUNT_TYPES.CHILDREN);
    let history = useHistory();

    const update = function (){
        store.dispatch({
            type: UPDATE_ACCOUNT_ACTION,
            payload: {
                account_type: choice
            }
        });

        history.push('/profile');
    }

    return (
        <motion.div className={"View"} id={"View__home"} initial={{ opacity: 0, right: -200, top: 0 }} animate={{ opacity: 1, right: 0, top: 0 }} exit={{ opacity: 0, right: -200, top: 0 }} transition={{ duration: 0.4 }}>
            <div className="App exp-screen">
                <ViewHeader className={"exp-header"}>
                    <div className={"View__title-bar"}>
                        {/*<Link className={"hamburger-link"} to={previousLink}>*/}
                        {/*    <img className={"caret-left"} src={close} alt={"Go back"} />*/}
                        {/*</Link>*/}

                        <div style={{display: "block", width: "100%", textAlign: "center"}} className={"View__title-bar-title exp-header-title"}>
                            Votre experience
                        </div>

                        <div/>
                    </div>
                </ViewHeader>

                <div className={"exp-content"}>
                    <p className={"exp-content__text"}>Choisissez votre expérience</p>
                    <Card className={choice === ACCOUNT_TYPES.CHILDREN ? "active" : ""} onClick={() => setChoice(ACCOUNT_TYPES.CHILDREN)}>
                        <CardPreview svg={children} />
                        <CardContent>
                            <span className={"exp-content__text text-center"}>Enfant</span>
                            <div className={"exp-radio"} >
                                <span className={"checkmark"}/>
                            </div>
                        </CardContent>
                    </Card>

                    <Card className={choice === ACCOUNT_TYPES.FAMILY ? "active" : ""} onClick={() => setChoice(ACCOUNT_TYPES.FAMILY)}>
                        <CardPreview svg={family} />
                        <CardContent>
                            <span className={"exp-content__text text-center"}>Famille</span>
                            <div className={"exp-radio"} >
                                <span className={"checkmark"}/>
                            </div>
                        </CardContent>
                    </Card>

                    <Card className={choice === ACCOUNT_TYPES.ADULTS ? "active" : ""} onClick={() => setChoice(ACCOUNT_TYPES.ADULTS)}>
                        <CardPreview svg={adult} />
                        <CardContent>
                            <span className={"exp-content__text text-center"}>Adulte</span>
                            <div className={"exp-radio"} >
                                <span className={"checkmark"}/>
                            </div>
                        </CardContent>
                    </Card>

                    <Button content={"Valider"} onClick={update} type={"is-large"}/>
                </div>
            </div>
        </motion.div>
    )
}
