import "./ProgressBar.css";
import {Progress} from "./Progress";
import React from "react";

interface ProgressBarProps {
    value: number;
    isLarge?: boolean;
}

export function ProgressBar ({value, isLarge}: ProgressBarProps) {
    if (isLarge) {
        return (
            <div className="ProgressBar ProgressBar__Large">
                <Progress value={Math.round(value)} isLarge={isLarge} />
            </div>
        );
    }

    return (
        <div className="ProgressBar">
            <Progress value={Math.round(value)} isLarge={isLarge} />
        </div>
    );
}