import React from "react";
import "./MissingWord.css";
import {motion} from "framer-motion";

interface MissingWordProps {
    content: string,
    good?: boolean,
    long?: boolean,
    onDrop?: any,
}

export function MissingWord ({ content, good, long, onDrop }: MissingWordProps) {
    let classes = good ? "Text__Missing-word good" : "Text__Missing-word";
    if (long) classes += " long";
    return (
        <motion.div className={classes}>
            {content}
        </motion.div>
    )
}