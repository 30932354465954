import React, {useRef} from "react";
import "./BrandQuizView.css";
import {motion} from "framer-motion";
import {ViewHeader} from "../View/ViewHeader";
import {ViewTitleBar} from "../View/ViewTitleBar";
import {ViewContent} from "../View/ViewContent";
import {Question} from "../Quiz/Question";
import {Statement} from "../Quiz/Statement";
import {Choices} from "../Text/Choices";
import {Choice} from "../Text/Choice";
import {MissingWord} from "../Text/MissingWord";
import {useSelector} from "react-redux";
import {VictoryView} from "./VictoryView";
import {brandQuizAnswers, currentBrandQuiz, selectQuestion, totalQuestionsBrandQuiz} from "../../store/brandQuizReducer";
import {BrandQuizState, store} from "../../store";
import popupIcon from "../../Assets/consigne/drag and drop.svg";
import {PopupTitle} from "../Popup/PopupTitle";
import {PopupDescription} from "../Popup/PopupDescription";
import {TestButton} from "../Buttons/TestButton";
import {Popup, popupButtonClicked} from "../Popup/Popup";
import {ADD_POINTS_ACTION, REMOVE_POINTS_ACTION, selectUser, USE_CLUE_ACTION} from "../../store/userReducer";
import {
    BRAND_QUIZ_CHECK_STATE, BRAND_QUIZ_CLUE, BRAND_QUIZ_REDRAW,
    BRAND_QUIZ_SELECT_CHOICE,
    BRAND_QUIZ_SKIP_QUESTION
} from "../../store/actionsList";
import Lottie from "react-lottie";
import {
    falseDefaultOptions,
    gsapAnimationsFrom,
    gsapAnimationsTo,
    questionBump,
    trueDefaultOptions
} from "../../store/constants";
import {TweenMax} from "gsap";
import {AvatarPopup} from "../Popup/AvatarPopup";
import {ClueButton} from "../Buttons/ClueButton";

let animate = false;

export function BrandQuizView () {
    const question = useSelector(selectQuestion);
    const user = useSelector(selectUser);

    const trueAnimation: any = useRef();
    const falseAnimation: any = useRef();

    let lottieStyle: any = {display: "none", pointerEvents: "none", position: "absolute", zIndex: "99", top: '50%', left: '50%', transform: 'translateX(-50%) translateY(-50%)'};

    const onDrop = (event: any) => {
        if (animationPlaying() || animate) return;
        const choice = event.target.innerText;
        let elements = document.querySelectorAll('.Text__Missing-word');
        if (elements) {
            elements.forEach((element) => {
                if (isInside(event.pageX, event.pageY, element.getBoundingClientRect())) {
                    animate = true;
                    store.dispatch({type: BRAND_QUIZ_SELECT_CHOICE, payload: choice});
                    if (goodAnswer(choice)) startTrueAnimation(choice);
                    else startFalseAnimation();
                    if (element.classList.contains('missing_word_hover')) element.classList.remove('missing_word_hover');
                }
            });
        }
    }

    const animationPlaying = (): boolean => {
        return (trueAnimation.current.anim.currentFrame > 0 && trueAnimation.current.anim.currentFrame < trueAnimation.current.anim.totalFrames - 1) ||
            (falseAnimation.current.anim.currentFrame > 0 && falseAnimation.current.anim.currentFrame < falseAnimation.current.anim.totalFrames - 1);
    }

    const startTrueAnimation = (text: string) => {
        if (trueAnimation.current) {
            trueAnimation.current.anim.stop();
            trueAnimation.current.anim.play();
            trueAnimation.current.el.style.display = "block";
        }

        let score = {val: user.brandQuizScore};
        let points = question.points + user.brandQuizScore;

        TweenMax.to(score, 1, {
            val: points,
            roundProps: "val",
            onUpdate: () => {
                let elem = document.querySelector('.View__Profile-information-score');
                if (elem) elem.innerHTML = Math.round(score.val) + " pts";
            },
            onComplete: () => {
                if (isComplete(question)) gsapAnimationsFrom();
                setTimeout(() => {
                    store.dispatch({type: ADD_POINTS_ACTION, payload: {score: question.points, type: "brand_quiz"}});
                    store.dispatch({type: BRAND_QUIZ_CHECK_STATE, payload: text});
                    if (isComplete(question)) {
                        questionBump();
                        gsapAnimationsTo();
                    }

                }, 300);
            }
        });

        setTimeout(() => { animate = false; }, 995);
        setTimeout(() => {
            if (trueAnimation.current) trueAnimation.current.el.style.display = "none";
            store.dispatch({type: BRAND_QUIZ_REDRAW});
        }, 1000);
    }

    const startFalseAnimation = () => {
        if (falseAnimation.current) {
            falseAnimation.current.el.style.display = "block";
            falseAnimation.current.anim.stop();
            falseAnimation.current.anim.play();
        }

        let score = {val: user.brandQuizScore};
        let points = user.brandQuizScore - 5;
        if (points < 0) points = 0;
        TweenMax.to(score, 1.0, {
            val: points,
            roundProps: "val",
            onUpdate: () => {
                let elem = document.querySelector('.View__Profile-information-score');
                if (elem) elem.innerHTML = Math.round(score.val) + " pts";
            },
            onComplete: () => {
                store.dispatch({type: REMOVE_POINTS_ACTION, payload: {points: 5, type: 'brand_quiz'}});
            }
        });

        if (isComplete(question))
        {
            gsapAnimationsFrom();
            setTimeout(() => {
                questionBump();
                gsapAnimationsTo();
            }, 300);
        }

        setTimeout(() => { animate = false; }, 995);
        setTimeout(() => {
            if (falseAnimation.current) falseAnimation.current.el.style.display = "none";
            store.dispatch({type: BRAND_QUIZ_REDRAW});
        }, 1000);
    }

    if (currentBrandQuiz === totalQuestionsBrandQuiz()) {
        return (
            <VictoryView type={"brand_quiz"} score={user.brandQuizScore} total={user.total} good={brandQuizAnswers.good} wrong={brandQuizAnswers.wrong} />
        );
    }
    else {
        return (
            <motion.div className={"View"} id={"View__Brand-quiz"} initial={{opacity: 0, right: -200, top: 0}}
                        animate={{opacity: 1, right: 0, top: 0}} exit={{opacity: 0, right: -200, top: 0}}
                        transition={{duration: 0.4}}>
                <ViewHeader>
                    <ViewTitleBar type={"brand_quiz"} title={"Le quiz de la marque"}/>
                    {/*<ViewScoreBar type={"brand_quiz"} />*/}
                </ViewHeader>

                <ViewContent>
                    <Question current={currentBrandQuiz + 1} max={totalQuestionsBrandQuiz()}/>
                    <Statement>
                        {question.statement}
                    </Statement>

                    <Choices little column>
                        {
                            question.found.map((found: string, k: number) => {
                                if (found === "") return <MissingWord content={""} key={k} />;
                                return <MissingWord content={found} key={k} good />
                            })
                        }
                    </Choices>

                    <div style={{marginBottom: 48}} />

                    <Choices column>
                        {
                            question.choices.map((choice: string, k: number) => {
                                if (!isGood(choice)) {
                                    if (isWrong(choice)) return <Choice draggable={false} content={choice} key={k} wrong />
                                    if (isDisabled(choice)) return <Choice draggable={false} content={choice} key={k} disabled />
                                    return <Choice draggable={!animate} onDrop={onDrop} onMouseMove={mouseMove} content={choice} key={k} />
                                }
                                return <Choice draggable={false} content={choice} key={k} found />
                            })
                        }
                    </Choices>

                    <Lottie ref={trueAnimation} style={lottieStyle} options={trueDefaultOptions} isClickToPauseDisabled width={300} height={300} />
                    <Lottie ref={falseAnimation} style={lottieStyle} options={falseDefaultOptions} isClickToPauseDisabled width={300} height={300} />

                    <div className="View__buttons">
                        { brandQuizAnswers.wrongsOnQuestion >= 0 ? <TestButton
                            content={"> PASSER"}
                            onClick={() => {
                                gsapAnimationsFrom();
                                setTimeout(() => {
                                    store.dispatch({type: BRAND_QUIZ_SKIP_QUESTION});
                                    questionBump();
                                    gsapAnimationsTo();
                                }, 500);
                            }}
                            type={"next"} /> : "" }
                        <ClueButton
                            content={"Besoin d'un indice ?"}
                            count={user.brandQuizClues}
                            onClick={() => {
                                if (user.brandQuizClues > 0 && !question.cluesUsed) {
                                    store.dispatch({type: USE_CLUE_ACTION, payload: {type: 'brand_quiz'}});
                                    store.dispatch({type: BRAND_QUIZ_CLUE});
                                }
                            }}
                        />
                    </div>
                </ViewContent>

                <Popup>
                    <img style={{marginBottom: "42px"}} src={popupIcon} alt={"Icon quiz de la marque"} />
                    <PopupTitle title={"Le Quiz de la Marque"} />
                    <PopupDescription>Faites glisser les bonnes réponses dans les emplacements.</PopupDescription>
                    <TestButton onClick={popupButtonClicked} content={"OK"} type={"is-large"} />
                </Popup>

                <AvatarPopup />
            </motion.div>
        );
    }
}

function mouseMove (event: any) {
    let elements = document.querySelectorAll('.Text__Missing-word');
    if (elements) {
        elements.forEach((element) => {
            if (isInside(event.pageX, event.pageY, element.getBoundingClientRect())) {
                if (!element.classList.contains('missing_word_hover')) {
                    element.classList.add('missing_word_hover');
                }
            }
            else {
                if (element.classList.contains('missing_word_hover')) element.classList.remove('missing_word_hover');
            }
        });
    }
}

function isInside (x: number, y: number, rect: DOMRect) {
    return (x >= rect.left && x <= rect.right && y >= rect.top && y <= rect.bottom);
}

function isWrong (choice: string): boolean {
    let state = store.getState().brandQuiz;
    for (let i = 0; i < state.wrongs.length; i++) {
        if (choice === state.wrongs[i]) return true;
    }

    return false;
}

function isDisabled(choice: string): boolean {
    let state = store.getState().brandQuiz;
    for (let i = 0; i < state.disabled.length; i++) {
        if (choice === state.disabled[i]) return true;
    }
    return false;
}


function isGood(choice: string): boolean {
    let state = store.getState().brandQuiz;
    for (let i = 0; i < state.found.length; i++) {
        if (choice === state.found[i]) return true;
    }

    return false;
}

function goodAnswer(choice: string): boolean {
    let state = store.getState().brandQuiz;
    for (let i = 0; i < state.responses.length; i++) if (choice === state.responses[i]) return true;

    return false;
}

const isComplete = (question: BrandQuizState): boolean => {
    for (let i = 0; i < question.responses.length; i++) {
        if (question.responses[i] !== question.found[i]) return false;
    }
    return true;
}
