import {BrandQuizState} from "./index";
import {
    ADD_BRAND_QUIZ_QUESTIONS,
    BRAND_QUIZ_CHECK_STATE,
    BRAND_QUIZ_CLUE, BRAND_QUIZ_REDRAW,
    BRAND_QUIZ_SELECT_CHOICE,
    BRAND_QUIZ_SKIP_QUESTION
} from "./actionsList";
import {shuffle} from "./emojiPuzzleReducer";

let brandQuizQuestions: BrandQuizState[] = [];

export let brandQuizAnswers = {
    good: 0,
    wrong: 0,
    wrongsOnQuestion: 0,
}

const initialState: BrandQuizState = brandQuizQuestions[0];

export let currentBrandQuiz = 0;

export const selectFound = (state: any) => state.brandQuiz.found;
export const selectQuestion = (state: any) => state.brandQuiz;

export const totalQuestionsBrandQuiz = () => brandQuizQuestions.length;
export const selectBrandQuizPercent = (state: any) => (currentBrandQuiz / totalQuestionsBrandQuiz() * 100) || 0;

export function brandQuizReducer (state = initialState, action: any) {
    switch (action.type) {
        case BRAND_QUIZ_SELECT_CHOICE:
            if (isGood(action.payload)) {
                for (let i = 0; i < state.responses.length; i++) {
                    if (state.found[i] === "" && !alreadyFound(action.payload)) {
                        brandQuizAnswers.good++;
                        state.found[i] = action.payload;
                        break;
                    }
                }

                return {
                    ...state
                }
            }
            brandQuizAnswers.wrongsOnQuestion++;
            brandQuizAnswers.wrong++;
            state.wrongs = [...state.wrongs, action.payload];
            if (noClues(state)) {
                state.cluesUsed = true;
            }
            return {
                ...state,
            };
        case BRAND_QUIZ_CHECK_STATE:
            if (everyoneFound()) {
                currentBrandQuiz++;
                brandQuizAnswers.wrongsOnQuestion = 0;
                if (brandQuizQuestions.length > currentBrandQuiz)
                    return brandQuizQuestions[currentBrandQuiz];
            }
            return {
                ...state
            }
        case BRAND_QUIZ_SKIP_QUESTION:
            currentBrandQuiz++;
            brandQuizAnswers.wrongsOnQuestion = 0;
            if (brandQuizQuestions.length > currentBrandQuiz) return brandQuizQuestions[currentBrandQuiz];
            return {
                ...state
            }
        case BRAND_QUIZ_CLUE:
            if (state.disabled.length >= state.choices.length - state.responses.length) return state;

            let currents = [];
            for (let i = 0; i < state.choices.length; i++) {
                if (!alreadyDisabled(state, state.choices[i]) && !isGood(state.choices[i]) && !alreadyWrong(state, state.choices[i])) currents.push(i);
            }

            if (currents.length === 0) {
                state.cluesUsed = true;
                return {...state};
            }

            let random = Math.floor(Math.random() * currents.length);
            state.disabled = [...state.disabled, state.choices[currents[random]]];

            if (noClues(state)) {
                state.cluesUsed = true;
            }

            return {
                ...state,
            }
        case BRAND_QUIZ_REDRAW:
            return {...state};
        case ADD_BRAND_QUIZ_QUESTIONS:
            if (action.payload && Array.isArray(action.payload))
            {
                let arr: BrandQuizState[] = [];
                action.payload.forEach((question: any) => {
                    let responses = question.goods.split(';');
                    let found = [];
                    for (let i = 0; i < responses.length; i++) {
                        if (responses[i].charAt(0) === ' ') responses[i] = responses[i].substr(1);
                        responses[i] = responses[i].replace(/\r?\n|\r/, "");
                        found.push("");
                    }

                    let wrongs = question.wrongs.split(';');
                    for (let i = 0; i < wrongs.length; i++) {
                        if (wrongs[i].charAt(0) === ' ') wrongs[i] = wrongs[i].substr(1);
                        wrongs[i] = wrongs[i].replace(/\r?\n|\r/, "");
                    }

                    let q: BrandQuizState = {
                        id: question.id,
                        statement: question.statement,
                        choices: shuffle([...responses, ...wrongs]),
                        responses: responses,
                        wrongs: [],
                        found: found,
                        disabled: [],
                        cluesUsed: false,
                        points: 25,
                    }

                    arr.push(q);
                });
                brandQuizQuestions = arr;
            }
            return brandQuizQuestions[0] || null;
        default:
            return state || null;
    }
}

function isGood (choice: string): boolean {
    for (let i = 0; i < brandQuizQuestions[currentBrandQuiz].responses.length; i++)
        if (brandQuizQuestions[currentBrandQuiz].responses[i] === choice) return true;

    return false;
}

function alreadyFound (choice: string): boolean {
    for (let i = 0; i < brandQuizQuestions[currentBrandQuiz].found.length; i++)
        if (brandQuizQuestions[currentBrandQuiz].found[i] === choice) return true;

    return false;
}

function alreadyDisabled (state: BrandQuizState, choice: string) {
    for (let i = 0; i < state.disabled.length; i++) {
        if (choice === state.disabled[i]) return true;
    }
    return false;
}

function everyoneFound (): boolean {
    for (let i = 0; i < brandQuizQuestions[currentBrandQuiz].found.length; i++)
        if (brandQuizQuestions[currentBrandQuiz].found[i] === "") return false;

    return true;
}

function alreadyWrong (state: BrandQuizState, choice: string) {
    for (let i = 0; i < state.wrongs.length; i++) {
        if (choice === state.wrongs[i]) return true;
    }
    return false;
}

function noClues (state: BrandQuizState) {
    console.log((state.disabled.length + state.wrongs.length + state.responses.length), state.choices.length);
    return state.disabled.length + state.wrongs.length + state.responses.length === state.choices.length;
}

export function brandQuizTotal () {
    let total = 0;
    for (let i = 0; i < brandQuizQuestions.length; i++) {
        total += brandQuizQuestions[i].points * brandQuizQuestions[i].responses.length;
    }

    return total;
}