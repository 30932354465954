import {motion} from "framer-motion";
import React from "react";
import "./experience_shared.css";

import {store} from "../../../store";
import {
    UPDATE_ACCOUNT_ACTION, EXPERIENCE_TYPES
} from "../../../store/userReducer";


import {Link, useHistory} from "react-router-dom";
import {ViewHeader} from "../../View/ViewHeader";
import {Card} from "../../ExperienceCard/Card";
import {CardPreview} from "../../ExperienceCard/CardPreview";
import {CardContent} from "../../ExperienceCard/CardContent";
import {TextField} from "../../TextField";
import {Button} from "../../Buttons/Button";

import "../../../Assets/close.svg";
import close from "../../../Assets/close.svg";
import solo from "../../../Assets/solo.svg";
import team from "../../../Assets/équipe.svg";

export function ProfileView (){

    const [choice, setChoice] = React.useState(EXPERIENCE_TYPES.SOLO);
    const [name, setName] = React.useState("");
    let history = useHistory();

    const update = function (){

        if (!name){
            return;
        }

        store.dispatch({
            type: UPDATE_ACCOUNT_ACTION,
            payload: {
                username: name,
                experience_type: choice
            }
        });

        history.push('/home');
    }

    return (
        <motion.div className={"View"} id={"View__home"} initial={{ opacity: 0, right: -200, top: 0 }} animate={{ opacity: 1, right: 0, top: 0 }} exit={{ opacity: 0, right: -200, top: 0 }} transition={{ duration: 0.4 }}>
            <div className="App exp-screen">
                <ViewHeader className={"exp-header"}>
                    <div className={"View__title-bar"}>
                        <Link className={"hamburger-link"} to={"/experience"}>
                            <img className={"caret-left"} src={close} alt={"Go back"} />
                        </Link>

                        <div className={"View__title-bar-title exp-header-title"}>
                            Votre experience
                        </div>

                        <div/>
                    </div>
                </ViewHeader>

                <div className={"exp-content"}>
                    <p className={"exp-content__text"}>Comment jouez vous ?</p>
                    <Card className={choice === EXPERIENCE_TYPES.SOLO ? "active" : ""} onClick={() => setChoice(EXPERIENCE_TYPES.SOLO)}>
                        <CardPreview svg={solo} />
                        <CardContent>
                            <span className={"exp-content__text text-center"}>Solo</span>
                            <div className={"exp-radio"} >
                                <span className={"checkmark"}/>
                            </div>
                        </CardContent>
                    </Card>

                    <Card className={choice === EXPERIENCE_TYPES.TEAM ? "active" : ""} onClick={() => setChoice(EXPERIENCE_TYPES.TEAM)}>
                        <CardPreview svg={team} />
                        <CardContent>
                            <span className={"exp-content__text text-center"}>Equipe</span>
                            <div className={"exp-radio"} >
                                <span className={"checkmark"}/>
                            </div>
                        </CardContent>
                    </Card>

                    <p className={"exp-content__text"}>Votre pseudo</p>

                    <TextField className={"mt-0"} onChange={(e:any) => {setName(e.target.value)}} />

                    <Button content={"Valider"} onClick={update} type={"is-large"}/>
                </div>
            </div>
        </motion.div>
    )
}
