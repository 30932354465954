import {TheListState} from "./index";
import {
    ADD_THE_LIST_QUESTIONS,
    THE_LIST_CHECK_STATE,
    THE_LIST_CLUE,
    THE_LIST_SKIP_QUESTION,
    THE_LIST_VALIDATION
} from "./actionsList";

let stringSimilarity = require('string-similarity');

export let theListQuestions: TheListState[] = [];

export let theListAnswers = {
    good: 0,
    wrong: 0,
    wrongsOnQuestion: 0
}

function getNumberFound (current: string[]) {
    let count = 0;
    for (let i = 0; i < current.length; i++) {
        if (!current[i].includes("_")) count++;
    }

    return count;
}

const initialState: TheListState = theListQuestions[0] || null;
export let currentTheList = 0;

export const totalQuestionsTheList = () => theListQuestions.length;
export const selectQuestion = (state: any) => state.theList;
export const selectTheListPercent = () => (currentTheList / totalQuestionsTheList() * 100) || 0;
export const selectNumberFound = (state: any) => getNumberFound(state.theList.current);

export function theListReducer (state = initialState, action: any) {
    switch (action.type) {
        case THE_LIST_VALIDATION:
            for (let i = 0; i < state.answers.length; i++) {
                if (stringSimilarity.compareTwoStrings(state.answers[i].toLowerCase(), action.payload.text.toLowerCase()) > 0.65 && !alreadyFound(state.answers[i], state)) {
                    theListAnswers.good++;
                    state.current.push(state.answers[i]);
                    return {...state};
                }
            }
            theListAnswers.wrong++;
            theListAnswers.wrongsOnQuestion++;
            return {...state};
        case THE_LIST_CLUE:
            return {
                ...state,
                helpClicked: true
            };
        case THE_LIST_CHECK_STATE:
            if (isGood(state)) {
                currentTheList++;
                theListAnswers.wrongsOnQuestion = 0;
                if (currentTheList < totalQuestionsTheList())
                    return theListQuestions[currentTheList];
            }
            console.log("return state");
            return state;
        case THE_LIST_SKIP_QUESTION:
            currentTheList++;
            theListAnswers.wrongsOnQuestion = 0;
            if (currentTheList < totalQuestionsTheList())
                return theListQuestions[currentTheList];

            return {
                ...state
            }
        case ADD_THE_LIST_QUESTIONS:
            if (action.payload && Array.isArray(action.payload))
            {
                let arr: TheListState[] = [];
                action.payload.forEach((question: any) => {
                    // console.log(question);
                    let answers = question.answers.split(';');
                    for (let i = 0; i < answers.length; i++)
                    {
                        if (answers[i].charAt(0) === ' ') answers[i] = answers[i].substr(1);
                        answers[i] = answers[i].replace(/\r?\n|\r/, "");
                    }

                    let q: TheListState = {
                        id: question.id,
                        statement: question.statement,
                        answers: answers,
                        current: [],
                        clue: question.clue,
                        nb_responses: question.nb_responses,
                        helpClicked: false,
                        points: 10,
                    }

                    arr.push(q);
                });
                theListQuestions = arr;
            }
            return theListQuestions[0];
        default:
            return state;
    }
}

function isGood(state: TheListState) {
    return state.current.length >= theListQuestions[currentTheList].nb_responses;
}

// function isInside(answer: string) {
//     for (let i = 0; i < theListQuestions[currentTheList].answers.length; i++) {
//         if (answer.toLowerCase() === theListQuestions[currentTheList].answers[i]) return true;
//     }
//
//     return false;
// }

function alreadyFound(answer: string, state: TheListState) {
    for (let i = 0; i < state.current.length; i++) if (state.current[i].toLowerCase() === answer) return true;
    return false;
}

export function theListTotal () {
    let total = 0;
    for (let i = 0; i < theListQuestions.length; i++) {
        total += theListQuestions[i].points * theListQuestions[i].answers.length;
    }

    return total;
}