import React from "react";
import "./Thumbnail.css";

interface ThumbnailProps {
    title: string;
}

export function Thumbnail({title}: ThumbnailProps) {
    return (
        <div className={"Thumbnail"}>
            {title}
        </div>
    );
}