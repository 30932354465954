import React from 'react';
import './App.css';

import {
  BrowserRouter as Router,
  Route
} from "react-router-dom";
import {HomeView} from "./views/HomeView";
import {MissingWordsView} from "./views/MissingWordsView";
import {TrueFalseView} from "./views/TrueFalseView";
import {YourExperienceView} from "./views/YourExperienceView";
import {Provider} from "react-redux";
import {store} from "../store";
import {HiddenCharacter} from "./views/HiddenCharacter";
import {BrandQuizView} from "./views/BrandQuizView";
import {EmojiPuzzleView} from "./views/EmojiPuzzleView";
import {TheListView} from "./views/TheListView";
import { EndView } from './views/EndView';
import {ProfileView} from "./views/Experience/ProfileView";
import {ExperienceView} from "./views/Experience/ExperienceView";
import {PlayView} from "./views/Experience/PlayView";
import {AwardsView} from "./views/AwardsView";


function App() {
    return (
        <Provider store={store}>
            <div className={"no_select"}>
                <Router>

                    <Route exact path={"/"}>
                        <PlayView />
                    </Route>

                    <Route path={"/experience"}>
                        <ExperienceView />
                    </Route>

                    <Route path={"/profile"}>
                        <ProfileView />
                    </Route>

                    <Route path={"/home"}>
                        <HomeView />
                    </Route>

                    <Route path={"/missing-words"}>
                        <MissingWordsView />
                    </Route>

                    <Route path={"/hidden-character"}>
                        <HiddenCharacter />
                    </Route>

                    <Route path={"/the-list"}>
                        <TheListView />
                    </Route>

                    <Route path={"/true-false"}>
                        <TrueFalseView />
                    </Route>

                    <Route path={"/brand-quiz"}>
                        <BrandQuizView />
                    </Route>

                    <Route path={"/emoji-puzzle"}>
                        <EmojiPuzzleView />
                    </Route>

                    <Route path={"/your-experience"}>
                        <YourExperienceView />
                    </Route>

                    <Route path={"/end"}>
                        <EndView />
                    </Route>

                    <Route path={"/awards"}>
                        <AwardsView />
                    </Route>
                </Router>
            </div>
        </Provider>
    );
}

export default App;
