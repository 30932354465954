import "./CardPreview.css";
import React from "react";

interface CardPreviewInterface {
    svg: any;
}

export function CardPreview ({svg}: CardPreviewInterface) {
    return (
        <div className="ExpCard__Preview">
            <div className="ExpCard__mask" />
            <img draggable={"false"} src={svg} alt={"The list"} />
        </div>
    );
}
