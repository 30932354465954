import "./PopupTitle.css";

interface PopupTitleProps {
    title: string,
    colored?: boolean,
}

export function PopupTitle ({title, colored}: PopupTitleProps) {
    let classes = "Popup__Title";
    if (colored) classes += " Popup__title__colored";
    return (
        <div className={classes}>
            {title}
        </div>
    );
}