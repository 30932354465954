import './ClueButton.css';
import React from "react";

interface ClueButtonProps {
    content: string;
    count: number;
    onClick?: any;
    disabled?: boolean;
}

export function ClueButton ({content, onClick, disabled, count}: ClueButtonProps) {
    return (
        <button style={{position: "relative"}} onClick={onClick} className={'Button help'}>
            <span className={"clueCount"}>{count}</span>
            { content }
        </button>
    );
}