import React from "react";
import "./Choice.css";
import {motion} from "framer-motion";
import icon from "../../Assets/indice.svg";

interface ChoiceProps {
    content: string,
    wrong?: boolean,
    large?: boolean,
    hidden?: boolean,
    found?: boolean,
    draggable: boolean,
    disabled?: boolean,
    onClick?: any,
    onDragStart?: any,
    onDrop?: any,
    onMouseMove?: any,
}

export function Choice ({content, wrong, large, draggable, hidden, found, disabled, onClick, onMouseMove, onDragStart, onDrop}: ChoiceProps) {
    let classes = wrong ? "Choices__Choice wrong" : "Choices__Choice";
    if (large) classes += " large";
    if (found) classes += " Choice__hidden";
    if (disabled) classes += " disabled"

    if (hidden) {
        return (
            <div className={classes} onClick={onClick}>
                <img src={icon} alt={"Icon indice"}/>
            </div>
        )
    }
    if (disabled) {
        return (
            <div className={classes}>
                {content}
            </div>
        )
    }
    if (found) {
        return (
            <div className={classes} />
        )
    }
    return (
        <motion.div drag={!wrong && draggable} dragElastic={1} dragConstraints={{top: 0, bottom: 0, left: 0, right: 0}} onDragStart={onDragStart} onDragEnd={onDrop} onTouchMove={onMouseMove} onMouseMove={onMouseMove} className={classes}>
            {content}
        </motion.div>
    );
}