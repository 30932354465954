import {HiddenCharacterState} from "./index";
import {
    ADD_HIDDEN_CHARACTER_QUESTIONS,
    HIDDEN_CHARACTER_REDRAW,
    HIDDEN_CHARACTER_REVEAL,
    HIDDEN_CHARACTER_SKIP_QUESTION,
    HIDDEN_CHARACTER_TRY_ANSWER
} from "./actionsList";
import stringSimilarity from "string-similarity";

let hiddenCharacterQuestions: HiddenCharacterState[] = [];

export let hiddenCharacterAnswers = {
    good: 0,
    wrong: 0
}

let initialState: HiddenCharacterState = hiddenCharacterQuestions[0];

export let currentHiddenCharacter = 0;

export const selectFound = (state: any) => state.hiddenCharacter.found;
export const selectQuestion = (state: any) => state.hiddenCharacter;

export const totalQuestionsHiddenCharacter = () => hiddenCharacterQuestions.length;
export const selectHiddenCharacterPercent = (state: any) => (currentHiddenCharacter / totalQuestionsHiddenCharacter() * 100) || 0;

export function hiddenCharacterReducer (state = initialState, action: any) {
    switch (action.type) {
        case HIDDEN_CHARACTER_REVEAL:
            if (action.payload) {
                if (state.choices[action.payload]) {
                    state.revealed[action.payload] = state.choices[action.payload];
                }
            }
            else {
                for (let i = 0; i < state.choices.length; i++) {
                    if (state.revealed[i] === '')
                    {
                        state.revealed[i] = state.choices[i];
                        if (allRevealed(state)) state.cluesUsed = true;
                        return {
                            ...state,
                        };
                    }
                }
            }

            return {
                ...state,
            };
        case HIDDEN_CHARACTER_TRY_ANSWER:
            if (stringSimilarity.compareTwoStrings(state.response.toLowerCase(), action.payload.toLowerCase()) > 0.65) {
                hiddenCharacterAnswers.good++;
                currentHiddenCharacter++;
                if (hiddenCharacterQuestions.length > currentHiddenCharacter)
                    return hiddenCharacterQuestions[currentHiddenCharacter];
                return {
                    ...state
                }
            }
            hiddenCharacterAnswers.wrong++;
            return {...state};
        case HIDDEN_CHARACTER_SKIP_QUESTION:
            currentHiddenCharacter++;
            if (hiddenCharacterQuestions.length > currentHiddenCharacter)
                return hiddenCharacterQuestions[currentHiddenCharacter];
            return {
                ...state
            }
        case HIDDEN_CHARACTER_REDRAW:
            return {...state};
        case ADD_HIDDEN_CHARACTER_QUESTIONS:
            if (action.payload && Array.isArray(action.payload))
            {
                let arr: HiddenCharacterState[] = [];
                action.payload.forEach((question: any) => {
                    let q: HiddenCharacterState = {
                        id: question.id,
                        statement: question.statement,
                        choices: [question.clue_1, question.clue_2, question.clue_3, question.clue_4],
                        revealed: [question.clue_1, question.clue_2, '', ''],
                        response: question.response,
                        points: 40,
                        cluesUsed: false,
                    }

                    arr.push(q);
                });
                hiddenCharacterQuestions = arr;
            }
            return hiddenCharacterQuestions[0];
        default:
            return state || null;
    }
}

export function hiddenCharacterTotal () {
    let total = 0;
    for (let i = 0; i < hiddenCharacterQuestions.length; i++) {
        total += hiddenCharacterQuestions[i].points;
    }

    return total;
}

function allRevealed (state: HiddenCharacterState) {
    for (let i = 0; i < state.revealed.length; i++) {
        if (state.revealed[i] !== state.choices[i]) return false;
    }
    return true;
}
