import React, {ReactNode} from "react";
import "./EmojiList.css";

interface EmojiListProps {
    children: ReactNode;
}

export function EmojiList ({children}: EmojiListProps) {
    return (
        <div className={"EmojiList"}>
            {children}
        </div>
    );
}