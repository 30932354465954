import React, {useRef} from "react";
import "./HiddenCharacter.css";
import {motion} from "framer-motion";
import {ViewHeader} from "../View/ViewHeader";
import {ViewTitleBar} from "../View/ViewTitleBar";
import {ViewContent} from "../View/ViewContent";
import {Question} from "../Quiz/Question";
import {Statement} from "../Quiz/Statement";
import {Choices} from "../Text/Choices";
import {Choice} from "../Text/Choice";
import {useSelector} from "react-redux";
import {
    currentHiddenCharacter, hiddenCharacterAnswers,
    selectQuestion,
    totalQuestionsHiddenCharacter,
} from "../../store/hiddenCharacterReducer";
import {VictoryView} from "./VictoryView";
import {store} from "../../store";
import {TextField} from "../TextField";
import popupIcon from "../../Assets/consigne/personnage caché.svg";
import {PopupTitle} from "../Popup/PopupTitle";
import {PopupDescription} from "../Popup/PopupDescription";
import {TestButton} from "../Buttons/TestButton";
import {Popup, popupButtonClicked} from "../Popup/Popup";
import {
    HIDDEN_CHARACTER_REVEAL, HIDDEN_CHARACTER_SKIP_QUESTION,
    HIDDEN_CHARACTER_TRY_ANSWER
} from "../../store/actionsList";
import {TweenMax} from "gsap";
import {ADD_POINTS_ACTION, REMOVE_POINTS_ACTION, selectUser, USE_CLUE_ACTION} from "../../store/userReducer";
import {
    falseDefaultOptions,
    gsapAnimationsFrom,
    gsapAnimationsTo, questionBump,
    trueDefaultOptions
} from "../../store/constants";
import Lottie from "react-lottie";
import {AvatarPopup} from "../Popup/AvatarPopup";
import {ClueButton} from "../Buttons/ClueButton";
import stringSimilarity from "string-similarity";

let animate = false;

export function HiddenCharacter () {
    const question = useSelector(selectQuestion);
    const user = useSelector(selectUser);

    const trueAnimation: any = useRef();
    const falseAnimation: any = useRef();

    let lottieStyle: any = {display: "none", pointerEvents: "none", position: "absolute", zIndex: "99", top: '50%', left: '50%', transform: 'translateX(-50%) translateY(-50%)'};

    const entered = (event: any) => {
        if (animationPlaying() || animate) return;

        if(event.key === 'Enter'){
            animate = true;
            if (isGood(event.target.value)) {
                startTrueAnimation(event.target.value);
            }
            else {
                startFalseAnimation();
            }

            event.target.value = "";
        }
    }

    const animationPlaying = (): boolean => {
        return (trueAnimation.current.anim.currentFrame > 0 && trueAnimation.current.anim.currentFrame < trueAnimation.current.anim.totalFrames - 1) ||
            (falseAnimation.current.anim.currentFrame > 0 && falseAnimation.current.anim.currentFrame < falseAnimation.current.anim.totalFrames - 1);
    }

    const startTrueAnimation = (text: string) => {
        trueAnimation.current.anim.stop();
        trueAnimation.current.anim.play();
        trueAnimation.current.el.style.display = "block";

        let score = {val: user.hiddenCharacterScore};
        let points = question.points + user.hiddenCharacterScore;

        TweenMax.to(score, 1.0, {
            val: points,
            roundProps: "val",
            onUpdate: () => {
                let elem = document.querySelector('.View__Profile-information-score');
                if (elem) elem.innerHTML = Math.round(score.val) + " pts";
            },
            onComplete: () => {
                gsapAnimationsFrom();
                setTimeout(() => {
                    let element: HTMLInputElement | null = document.querySelector('.TextField');
                    if (element) element.value = "";
                    store.dispatch({type: ADD_POINTS_ACTION, payload: {score: question.points, type: "hidden_character"}});
                    store.dispatch({type: HIDDEN_CHARACTER_TRY_ANSWER, payload: text});

                    questionBump();
                    gsapAnimationsTo();

                }, 300);
            }
        });

        setTimeout(() => { if (trueAnimation.current) trueAnimation.current.el.style.display = "none"; animate = false;}, 1000);
    }

    const startFalseAnimation = () => {
        falseAnimation.current.el.style.display = "block";
        falseAnimation.current.anim.stop();
        falseAnimation.current.anim.play();

        let score = {val: user.hiddenCharacterScore};
        let points = user.hiddenCharacterScore - 5;
        if (points < 0) points = 0;
        TweenMax.to(score, 1.0, {
            val: points,
            roundProps: "val",
            onUpdate: () => {
                let elem = document.querySelector('.View__Profile-information-score');
                if (elem) elem.innerHTML = Math.round(score.val) + " pts";
            },
            onComplete: () => {
                setTimeout(() => {
                    let element: HTMLInputElement | null = document.querySelector('.TextField');
                    if (element) element.value = "";
                    store.dispatch({type: HIDDEN_CHARACTER_TRY_ANSWER, payload: ""});
                }, 300);
                store.dispatch({type: REMOVE_POINTS_ACTION, payload: {points: 5, type: 'hidden_character'}});
            }
        });

        setTimeout(() => {
            if (falseAnimation.current) falseAnimation.current.el.style.display = "none";
            animate = false;
        }, 1000);
    }

    if (currentHiddenCharacter === totalQuestionsHiddenCharacter()) {
        return (
            <VictoryView type={"hidden_character"} score={user.hiddenCharacterScore} total={user.total} good={hiddenCharacterAnswers.good} wrong={hiddenCharacterAnswers.wrong} />
        );
    }
    else {
        return (
            <motion.div className={"View"} id={"View__Hidden-character"} initial={{ opacity: 0, right: -200, top: 0 }} animate={{ opacity: 1, right: 0, top: 0 }} exit={{ opacity: 0, right: -200, top: 0 }} transition={{ duration: 0.4 }}>
                <ViewHeader>
                    <ViewTitleBar type={"hidden_character"} title={"Personnage caché"} />
                    {/*<ViewScoreBar type={"hidden_character"} />*/}
                </ViewHeader>

                <ViewContent>
                    <Question current={currentHiddenCharacter + 1} max={totalQuestionsHiddenCharacter()} />
                    <Statement>
                        {question.statement}
                    </Statement>

                    <Choices column little>
                        {
                            question.choices.map((choice: string, k: number) => {
                                if (question.revealed[k] === choice) {
                                    return <Choice draggable={false} key={k} content={choice} />;
                                }
                                return <Choice draggable={false} key={k} content={""} hidden />;
                            })
                        }
                    </Choices>

                    <div>
                        <TextField onKeyPress={entered} />
                        <TestButton
                            content='Valider'
                            type={'center long'}
                            onClick={() => {
                                if (animationPlaying() || animate) return;
                                let element: HTMLInputElement | null = document.querySelector('.TextField');
                                if (element) {
                                    let text = element.value;
                                    animate = true;
                                    if (!text) text = "";
                                    if (isGood(text)) {
                                        startTrueAnimation(text);
                                    }
                                    else {
                                        startFalseAnimation();
                                    }
                                    element.value = "";
                                }
                            }}
                        />
                    </div>

                    <Lottie ref={trueAnimation} style={lottieStyle} options={trueDefaultOptions} isClickToPauseDisabled width={300} height={300} />
                    <Lottie ref={falseAnimation} style={lottieStyle} options={falseDefaultOptions} isClickToPauseDisabled width={300} height={300} />

                    <div className="View__buttons">
                        <TestButton
                            content={"> PASSER"}
                            onClick={() => {
                                gsapAnimationsFrom();
                                setTimeout(() => {
                                    store.dispatch({type: HIDDEN_CHARACTER_SKIP_QUESTION});
                                    questionBump();
                                    gsapAnimationsTo();
                                }, 500);
                            }}
                            type={"next"} />
                        <ClueButton
                            content={"Besoin d'un indice ?"}
                            count={user.hiddenCharacterClues}
                            onClick={() => {
                                if (user.hiddenCharacterClues > 0 && !question.cluesUsed)
                                {
                                    store.dispatch({type: USE_CLUE_ACTION, payload: {type: 'hidden_character'}});
                                    store.dispatch({type: HIDDEN_CHARACTER_REVEAL});
                                }
                            }}
                        />
                    </div>
                </ViewContent>

                <Popup>
                    <img style={{marginBottom: "42px"}} src={popupIcon} alt={"Icon vrai ou faux"} />
                    <PopupTitle title={"Personnage caché"} />
                    <PopupDescription>Tapez la bonne réponse et validez !</PopupDescription>
                    <TestButton onClick={popupButtonClicked} content={"OK"} type={"is-large"} />
                </Popup>

                <AvatarPopup />
            </motion.div>
        );
    }
}

function isGood (answer: string)
{
    const state = store.getState().hiddenCharacter;
    return stringSimilarity.compareTwoStrings(answer.toLowerCase(), state.response.toLowerCase()) > 0.65;
}