import React, {useRef} from "react";
import "./MissingWordsView.css";
import {currentMissingWords, missingWordsAnswers, totalQuestionsMissingWords} from "../../store/missingWordsReduce";
import {VictoryView} from "./VictoryView";
import {motion} from "framer-motion";
import {ViewHeader} from "../View/ViewHeader";
import {ViewTitleBar} from "../View/ViewTitleBar";
import {ViewContent} from "../View/ViewContent";
import {Question} from "../Quiz/Question";
import {Statement} from "../Quiz/Statement";
import {Text} from "../Text/Text";
import {Line} from "../Text/Line";
import {MissingWord} from "../Text/MissingWord";
import {Choices} from "../Text/Choices";
import {Choice} from "../Text/Choice";
import {useSelector} from "react-redux";
import {selectQuestion} from "../../store/missingWordsReduce";
import {MissingWordsState, store} from "../../store";
import popupIcon from "../../Assets/consigne/drag and drop.svg";
import {PopupTitle} from "../Popup/PopupTitle";
import {PopupDescription} from "../Popup/PopupDescription";
import {TestButton} from "../Buttons/TestButton";
import {Popup, popupButtonClicked} from "../Popup/Popup";
import {ADD_POINTS_ACTION, REMOVE_POINTS_ACTION, selectUser, USE_CLUE_ACTION} from "../../store/userReducer";
import {
    MISSING_WORDS_ADD_CHOICE, MISSING_WORDS_CHECK_STATE, MISSING_WORDS_CLUE, MISSING_WORDS_REDRAW,
    MISSING_WORDS_SKIP_QUESTION,
} from "../../store/actionsList";
import Lottie from "react-lottie";
import {TweenMax} from "gsap";
import {
    falseDefaultOptions,
    gsapAnimationsFrom, gsapAnimationsTo,
    questionBump,
    trueDefaultOptions
} from "../../store/constants";
import {AvatarPopup} from "../Popup/AvatarPopup";
import {ClueButton} from "../Buttons/ClueButton";

let animate = false;

export function MissingWordsView() {
    const question = useSelector(selectQuestion);
    const user = useSelector(selectUser);
    let currentWord = -1;

    const onDrop = (event: any) => {
        if (animate || animationPlaying()) return;
        const choice = event.target.innerText;
        let elements = document.querySelectorAll('.Text__Missing-word');
        let i = 0;
        if (elements) {
            elements.forEach((element) => {
                if (isInside(event.pageX, event.pageY, element.getBoundingClientRect())) {
                    animate = true;
                    store.dispatch({type: MISSING_WORDS_ADD_CHOICE, payload: {choice: choice, index: i }});
                    if (goodAnswer(choice)) startTrueAnimation();
                    else startFalseAnimation();
                    if (element.classList.contains('missing_word_hover')) element.classList.remove('missing_word_hover');
                }
                i++;
            });
        }
    }

    const trueAnimation: any = useRef();
    const falseAnimation: any = useRef();

    let lottieStyle: any = {display: "none", pointerEvents: "none", position: "absolute", zIndex: "99", top: '50%', left: '50%', transform: 'translateX(-50%) translateY(-50%)'};

    const animationPlaying = (): boolean => {
        return (trueAnimation.current.anim.currentFrame > 0 && trueAnimation.current.anim.currentFrame < trueAnimation.current.anim.totalFrames - 1) ||
            (falseAnimation.current.anim.currentFrame > 0 && falseAnimation.current.anim.currentFrame < falseAnimation.current.anim.totalFrames - 1);
    }

    const startTrueAnimation = () => {
        if (trueAnimation.current) {
            trueAnimation.current.anim.stop();
            trueAnimation.current.anim.play();
            trueAnimation.current.el.style.display = "block";
        }

        let score = {val: user.missingWordScore};
        let points = question.points + user.missingWordScore;

        TweenMax.to(score, 1, {
            val: points,
            roundProps: "val",
            onUpdate: () => {
                let elem = document.querySelector('.View__Profile-information-score');
                if (elem) elem.innerHTML = Math.round(score.val) + " pts";
            },
            onComplete: () => {
                if (isComplete(question)) gsapAnimationsFrom();
                setTimeout(() => {
                    store.dispatch({type: ADD_POINTS_ACTION, payload: {score: question.points, type: 'missing_words'}});
                    store.dispatch({type: MISSING_WORDS_CHECK_STATE});

                    if (isComplete(question)) {
                        gsapAnimationsTo();
                        questionBump();
                        animate = false;
                    }

                }, 500);
            }
        });

        setTimeout(() => {
            if (trueAnimation.current) trueAnimation.current.el.style.display = "none";
            animate = false;
            store.dispatch({type: MISSING_WORDS_REDRAW});
        }, 1000);
    }

    const startFalseAnimation = () => {
        if (falseAnimation.current) {
            falseAnimation.current.el.style.display = "block";
            falseAnimation.current.anim.stop();
            falseAnimation.current.anim.play();
        }

        let score = {val: user.missingWordScore};
        let points = user.missingWordScore - 5;
        if (points < 0) points = 0;
        TweenMax.to(score, 1, {
            val: points,
            roundProps: "val",
            onUpdate: () => {
                let elem = document.querySelector('.View__Profile-information-score');
                if (elem) elem.innerHTML = Math.round(score.val) + " pts";
            },
            onComplete: () => {
                store.dispatch({type: REMOVE_POINTS_ACTION, payload: {points: 5, type: 'missing_words'}});
            }
        });

        if (isComplete(question)) gsapAnimationsFrom();
        setTimeout(() => {
            store.dispatch({type: MISSING_WORDS_CHECK_STATE});
            if (isComplete(question)) {
                questionBump();
                gsapAnimationsTo();
                animate = false;
            }
        }, 500);
        setTimeout(() => {
            if (falseAnimation.current) falseAnimation.current.el.style.display = "none";
            animate = false;
            store.dispatch({type: MISSING_WORDS_REDRAW});
        }, 1000);
    }

    if (currentMissingWords === totalQuestionsMissingWords()) {
        return (
            <VictoryView type={"missing_words"} score={user.missingWordScore} total={user.total} good={missingWordsAnswers.good} wrong={missingWordsAnswers.wrong} />
        );
    }
    else {
        return (
            <motion.div className={"View"} id={"View__missing-words"} initial={{ opacity: 0, right: -200, top: 0 }} animate={{ opacity: 1, right: 0, top: 0 }} exit={{ opacity: 0, right: -200, top: 0 }} transition={{ duration: 0.4 }}>
                <ViewHeader>
                    <ViewTitleBar type={"missing_words"} title={"Mots manquants"} />
                </ViewHeader>

                <ViewContent>
                    <Question current={currentMissingWords + 1} max={totalQuestionsMissingWords()} />
                    <Statement>
                        {question.statement}
                    </Statement>

                    <Text>
                        {

                            question.lines.map((line: string, k: number) => {
                                let splits = line.split(/([_])|(?:=[_])/g);
                                let el =  splits.map((sequence: string, v: number) => {
                                    if (sequence === '_') {
                                        currentWord++;
                                        if (currentWord >= 0 && question.goods[currentWord] && question.goods[currentWord] === question.responses[currentWord]) {
                                            return <MissingWord key={k * v + v} content={question.goods[currentWord]} good />
                                        }
                                        // currentWord++;
                                        return <MissingWord key={k * v + v} onDrop={(event: any) => {console.log(event)}} content={""} />
                                    }
                                    return <span key={k * v + v}>{ sequence }</span>;
                                });
                                return <Line key={k}>{el}</Line>
                            })
                        }
                    </Text>

                    <Choices column>
                        {
                            question.choices.map((choice: string, k: number) => {
                                if (!isGood(choice)) {
                                    if (isWrong(choice)) return <Choice draggable={false} content={choice} onDrop={onDrop} onMouseMove={mouseMove} key={k} wrong />
                                    if (isDisabled(choice)) return <Choice draggable={false} content={choice} key={k} disabled />
                                    return <Choice draggable={!animate} onDrop={onDrop} onMouseMove={mouseMove} content={choice} key={k} />
                                }
                                return <Choice draggable={false} content={choice} key={k} found />;
                            })
                        }
                    </Choices>

                    <Lottie ref={trueAnimation} style={lottieStyle} options={trueDefaultOptions} isClickToPauseDisabled width={300} height={300} />
                    <Lottie ref={falseAnimation} style={lottieStyle} options={falseDefaultOptions} isClickToPauseDisabled width={300} height={300} />

                    <div className="View__buttons">
                        {missingWordsAnswers.wrongsOnQuestion >= 0 ? <TestButton
                            content={"> PASSER"}
                            onClick={() => {
                                gsapAnimationsFrom();
                                setTimeout(() => {
                                    store.dispatch({type: MISSING_WORDS_SKIP_QUESTION});
                                    questionBump();
                                    gsapAnimationsTo();
                                }, 500);
                            }}
                            type={"next"} /> : ''}
                        <ClueButton
                            content={"Besoin d'un indice ?"}
                            count={user.missingWordClues}
                            onClick={() => {
                                if (user.missingWordClues > 0 && !question.cluesUsed && !animate) {
                                    store.dispatch({type: MISSING_WORDS_CLUE});
                                    store.dispatch({type: USE_CLUE_ACTION, payload: {type: "missing_words"}});
                                }
                            }}
                        />
                    </div>
                </ViewContent>

                <Popup>
                    <img style={{marginBottom: "42px"}} src={popupIcon} alt={"Icon vrai ou faux"} />
                    <PopupTitle title={"Mots manquants"} />
                    <PopupDescription>Faites glisser les bonnes réponses dans les emplacements.</PopupDescription>
                    <TestButton onClick={popupButtonClicked} content={"OK"} type={"is-large"} />
                </Popup>

                <AvatarPopup />
            </motion.div>
        );
    }
}

function mouseMove (event: any) {
    let elements = document.querySelectorAll('.Text__Missing-word');
    if (elements) {
        elements.forEach((element) => {
            let x = event.pageX;
            let y = event.pageY;
            if (event.type === "touchmove" && event.touches.length > 0) {
                x = event.touches[0].pageX;
                y = event.touches[0].pageY;
            }
            if (isInside(x, y, element.getBoundingClientRect())) {
                if (!element.classList.contains('missing_word_hover')) {
                    element.classList.add('missing_word_hover');
                }
            }
            else {
                if (element.classList.contains('missing_word_hover')) element.classList.remove('missing_word_hover');
            }
        });
    }
}

function isInside (x: number, y: number, rect: DOMRect) {
    return (x >= rect.left && x <= rect.right && y >= rect.top && y <= rect.bottom);
}

const isComplete = (question: MissingWordsState) => {
    for (let i = 0; i < question.responses.length; i++) {
        if (question.responses[i] !== question.goods[i]) return false;
    }
    return true;
}

function isWrong (choice: string): boolean {
    let state = store.getState().missingWords;
    for (let i = 0; i < state.wrongs.length; i++) {
        if (choice === state.wrongs[i]) return true;
    }

    return false;
}

function isGood(choice: string): boolean {
    let state = store.getState().missingWords;
    for (let i = 0; i < state.goods.length; i++) {
        if (choice === state.goods[i]) return true;
    }

    return false;
}

function isDisabled(choice: string): boolean {
    let state = store.getState().missingWords;
    for (let i = 0; i < state.disabled.length; i++) {
        if (choice === state.disabled[i]) return true;
    }
    return false;
}

function goodAnswer(choice: string): boolean {
    let state = store.getState().missingWords;
    for (let i = 0; i < state.responses.length; i++) if (choice === state.responses[i]) return true;

    return false;
}
