import "./CardTitle.css";
import React from "react";

interface CardTitleProps {
    title: string;
}

export function CardTitle ({title}: CardTitleProps) {
    return (
        <div className="Card__Title">
            {title}
        </div>
    );
}