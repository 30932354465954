import "./ViewHeader.css";
import React, {ReactNode} from "react";

interface ViewHeaderProps {
    children: ReactNode;
    className?: string;
}

export function ViewHeader({children, className = ""}: ViewHeaderProps) {
    return (
        <div className={"View__Header " + className}>
            {children}
        </div>
    );
}
