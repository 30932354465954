import React from "react";
import {Link} from "react-router-dom";
import "./ViewTitleBar.css";

import close from "../../Assets/close.svg";
import {useSelector} from "react-redux";
import {selectUser} from "../../store/userReducer";
import gsap from "gsap";

interface ViewTitleBarProps {
    title: string;
    type?: string;
}

export function ViewTitleBar({title, type}: ViewTitleBarProps) {
    const user = useSelector(selectUser);
    let score = 0;
    if (type) {
        switch (type) {
            case "missing_words":
                score = user.missingWordScore;
                break;
            case "brand_quiz":
                score = user.brandQuizScore;
                break;
            case "hidden_character":
                score = user.hiddenCharacterScore;
                break;
            case "emoji_puzzle":
                score = user.emojiPuzzleScore;
                break;
            case "the_list":
                score = user.theListScore;
                break;
            case "true_false":
                score = user.trueFalseScore;
                break;
        }
    }

    return (
        <div className={"View__title-bar"}>
            <Link className={"hamburger-link"} to={"/home"}>
                <img src={close} alt={"Go back"} />
            </Link>

            <div className={"View__title-bar-title"}>
                {title}
            </div>

            {type ? <div className="View__Profile-information-score">{score} pts</div> : <span />}

            <div style={{cursor: "pointer"}} onClick={() => {showPopup();}} className={"View__title-bar-information"}>
                i
            </div>
        </div>
    );
}

function showPopup() {
    let element = document.querySelector('.Popup');
    if (element) element.classList.remove('none');
    gsap.to('.Popup__Mask', {
        y: 0,
        duration: 0.5
    });
    gsap.to('.Popup', {
        opacity: 1,
        duration: 0.5
    });
}