import {motion} from "framer-motion";
import React from "react";
import "./experience_shared.css";
import "../../Reward.css";
import "./PlayView.css";

import {ButtonLink} from "../../Buttons/ButtonLink";

import "../../../Assets/close.svg";
import RewardIcon from "../../../Assets/gift-box.svg";
import PlayIcon from "../../../Assets/gift-box.svg";
import {store} from "../../../store";
import {
    ADD_BRAND_QUIZ_QUESTIONS,
    ADD_EMOJI_PUZZLE_QUESTIONS, ADD_HIDDEN_CHARACTER_QUESTIONS,
    ADD_MISSING_WORDS_QUESTIONS,
    ADD_THE_LIST_QUESTIONS,
    ADD_TRUE_FALSE_QUESTIONS
} from "../../../store/actionsList";
import {Popup, popupButtonClicked} from "../../Popup/Popup";
import {PopupTitle} from "../../Popup/PopupTitle";
import {PopupDescription} from "../../Popup/PopupDescription";
import {TestButton} from "../../Buttons/TestButton";
import gsap from "gsap";

let ready = false;

export function PlayView (){
    let isAsideLocation = true;

    let types = "is-large play-button";
    if (!ready) types += " waiting";

    const urlParams = new URLSearchParams(window.location.search);
    const id = urlParams.get('id');

    const link = `/experience`;

    let headers = new Headers();
    headers.append('Authorization', 'Bearer f78ds654654sd54s654d6s54d65s4');
    fetch(`https://bill-games.davikingcode.eu/api/operation/${id}/logo`, {
    // fetch(`http://billgames.test/api/operation/${id}/logo`, {
        headers: headers,
        method: 'GET'
    }).then((response) => {
        if (response.status === 404)
        {
            showPopup();
            return Promise.reject("Cette opération n'existe pas.");
        }
        return response.json();
    }).then((data) => {
        let el: HTMLElement|null = document.querySelector('.play-splash');
        if (data.logo) {
            console.log(data);
            if (el) el.setAttribute('src', data.logo);
        }
        if (el) el.style.display = "block";

        // fetch(`http://billgames.test/api/operation/${id}`, {
        fetch(`https://bill-games.davikingcode.eu/api/operation/${id}`, {
            headers: headers,
            method: 'GET'
        }).then((response) => {
            if (response.status === 404)
            {
                return Promise.reject("Cette opération n'existe pas.");
            }
            return response.json();
        }).then((operationData) => {
            if (operationData.the_list && operationData.the_list.length > 0) store.dispatch({type: ADD_THE_LIST_QUESTIONS, payload: operationData.the_list});
            if (operationData.true_false && operationData.true_false.length > 0) store.dispatch({type: ADD_TRUE_FALSE_QUESTIONS, payload: operationData.true_false});
            if (operationData.missing_words && operationData.missing_words.length > 0) store.dispatch({type: ADD_MISSING_WORDS_QUESTIONS, payload: operationData.missing_words});
            if (operationData.emoji_puzzle && operationData.emoji_puzzle.length > 0) store.dispatch({type: ADD_EMOJI_PUZZLE_QUESTIONS, payload: operationData.emoji_puzzle});
            if (operationData.brand_quiz && operationData.brand_quiz.length > 0) store.dispatch({type: ADD_BRAND_QUIZ_QUESTIONS, payload: operationData.brand_quiz});
            if (operationData.hidden_character && operationData.hidden_character.length > 0) store.dispatch({type: ADD_HIDDEN_CHARACTER_QUESTIONS, payload: operationData.hidden_character});

            let el = document.querySelector(".waiting");
            if (el) el.classList.remove("waiting");
        });
    })


    if (!isAsideLocation)
    {
        window.confirm("Vous devez être dans un rayon de 500m du restaurant pour pouvoir participer à l'application. Veuillez vous rapprocher de la zone.")
        return (
            <div />
        )
    }

    return (
        <motion.div className={"View"} id={"View__home"} initial={{ opacity: 0, right: -200, top: 0 }} animate={{ opacity: 1, right: 0, top: 0 }} exit={{ opacity: 0, right: -200, top: 0 }} transition={{ duration: 0.4 }}>
            <div className="App exp-screen play-screen">
                <div className={"exp-content"}>

                    <div className={"play-splash-container"}>
                        <img draggable={false} style={{display: "none"}} className={"play-splash"} src={PlayIcon} alt={"Play Splash"}/>
                    </div>

                    <ButtonLink content={"Jouer"} link={link} type={types}/>

                </div>

                <div className="Reward">
                    <div className="Reward__Preview play-preview">
                        <img draggable={"false"} className={"Reward__Icon play-icon"} src={RewardIcon} alt={"Reward icon"} />
                    </div>

                    <div className="Reward__Content">
                        <div className="Reward__Label play-label">
                            Complétez les différentes épreuves et tentez de gagner des cadeaux !
                        </div>
                    </div>
                </div>

                <Popup none>
                    <PopupTitle title={"Erreur"} />
                    <PopupDescription>Cette opération est terminée ou n'existe pas. Veuillez scanner un QR Code valide.</PopupDescription>
                    <TestButton onClick={popupButtonClicked} content={"OK"} type={"is-large"} />
                </Popup>
            </div>
        </motion.div>
    )
}

function showPopup() {
    let element = document.querySelector('.Popup');
    if (element) element.classList.remove('none');
    gsap.to('.Popup > .Popup__Mask', {
        y: 0,
        duration: 0.5
    });
    gsap.to('.Popup', {
        opacity: 1,
        duration: 0.5
    });
}