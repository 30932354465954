import "./Card.css";
import React, {ReactNode} from "react";

interface CardProps {
    children: ReactNode;
}

export function Card ({children}: CardProps) {
    return (
        <div className="Card">
            { children }
        </div>
    );
}