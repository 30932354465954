import React, {ReactNode} from "react";
import "./ViewContent.css";

interface ViewContentProps {
    children: ReactNode;
}

export function ViewContent({children}: ViewContentProps) {
    return (
        <div className={"View__Content"}>
            {children}
        </div>
    );
}