import {TrueFalseState} from "./index";
import {
    ADD_TRUE_FALSE_QUESTIONS,
    TRUE_FALSE_CLUE,
    TRUE_FALSE_FALSE,
    TRUE_FALSE_SKIP_QUESTION,
    TRUE_FALSE_TRUE
} from "./actionsList";

let questions: TrueFalseState[] = [];

export let trueFalseAnswers = {
    good: 0,
    wrong: 0
}

const initialState: TrueFalseState = questions[0];

export let currentTrueFalse = 0;
export const totalQuestionsTrueFalse = () => questions.length;

export const selectFound = (state: any) => state.trueFalse.found;
export const selectQuestion = (state: any) => state.trueFalse;
export const selectTrueFalsePercent = (state: any) => (currentTrueFalse / totalQuestionsTrueFalse() * 100) || 0;

export function trueFalseReducer (state = initialState, action: any) {
    switch (action.type)
    {
        case TRUE_FALSE_TRUE:
            if (state.response.toLowerCase() === "vrai")
            {
                trueFalseAnswers.good++;
                state.found = true;
            }
            else {
                trueFalseAnswers.wrong++;
            }

            currentTrueFalse++;
            if (questions.length > currentTrueFalse) return questions[currentTrueFalse];

            return { ...state }
        case TRUE_FALSE_FALSE:
            if (state.response.toLowerCase() === "faux")
            {
                trueFalseAnswers.good++;
                state.found = true;
            }
            else {
                trueFalseAnswers.wrong++;
            }

            currentTrueFalse++;
            if (questions.length > currentTrueFalse) return questions[currentTrueFalse];

            return { ...state }
        case TRUE_FALSE_SKIP_QUESTION:
            currentTrueFalse++;
            if (questions.length > currentTrueFalse) return questions[currentTrueFalse];
            return { ...state }
        case TRUE_FALSE_CLUE:
            state.clueUsed = true;
            return { ...state }
        case ADD_TRUE_FALSE_QUESTIONS:
            if (action.payload && Array.isArray(action.payload))
            {
                let arr: TrueFalseState[] = [];
                action.payload.forEach((question: any) => {
                    let r = "none";
                    if (question.response.toLowerCase().includes("vrai")) r = "vrai";
                    else if (question.response.toLowerCase().includes("faux")) r = "faux";

                    let q: TrueFalseState = {
                        id: question.id,
                        statement: question.statement,
                        response: r,
                        points: 50,
                        found: false,
                        clue: question.clue,
                        clueUsed: false,
                    }

                    arr.push(q);
                });
                questions = arr;
            }
            return questions[0];
        default:
            return state || null;
    }
}

export function trueFalseTotal (): number {
    let total = 0;
    for (let i = 0; i < questions.length; i++) {
        total += questions[i].points
    }

    return total;
}