import "./Card.css";
import React, {ReactNode} from "react";

interface CardProps {
    children: ReactNode;
    className?: string;
    onClick?: any;
}

export function Card ({children, className="", onClick}: CardProps) {
    return (
        <div className={"ExpCard " + className} onClick={onClick}>
            { children }
        </div>
    );
}
