import "./VictoryView.css";
import React from "react";
import {TotalScore} from "../TotalScore";
import {motion} from "framer-motion";
import Lottie from "react-lottie";
import animationData from "../../Assets/gift.json";
import {ButtonLink} from "../Buttons/ButtonLink";

import goodImage from "../../Assets/réponses justes.png";
import wrongImage from "../../Assets/réponses fausses.png";

interface VictoryViewProps {
    score: number,
    total: number,
    wrong: number,
    good: number,
    type: string,
}

export function VictoryView ({score, total, wrong, type, good}: VictoryViewProps) {
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    };

    const trophySize = window.innerHeight * 0.25;

    return (
        <motion.div className={"View View__Purple"} id={"View__victory"} initial={{ opacity: 0, right: -200 }} animate={{ opacity: 1, right: 0 }} exit={{ opacity: 0, right: -200 }} transition={{ duration: 0.4 }}>
            <div className={"Victory__Your-score"}>Votre score</div>
            <h1 className={"Victory__Great"}>Bien joué !</h1>

            <div className="Victory__Animation">
                <Lottie options={defaultOptions} isClickToPauseDisabled width={trophySize} height={trophySize} />
            </div>

            <TotalScore type={type} score={score} total={total} />

            <div className="Victory__Answers">
                <div className="Victory__Answers__Good">
                    <img className={"Victory__Icon"} src={goodImage} alt="Bonnes réponses"/>
                    <span className={"Victory__Label"}>Réponses correctes</span>
                    <span className={"Victory__Answer__Count"}>{good}</span>
                </div>

                <div className="Victory__Separator" />

                <div className="Victory__Answers__Wrong">
                    <img className={"Victory__Icon"} src={wrongImage} alt="Bonnes réponses"/>
                    <span className={"Victory__Label"}>Réponses incorrectes</span>
                    <span className={"Victory__Answer__Count"}>{wrong}</span>
                </div>
            </div>

            <ButtonLink link={"/home"} content={"Continuer"} type={"hidden"} />
        </motion.div>
    );
}
