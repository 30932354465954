import React, {ReactNode} from "react";
import "./Choices.css";

interface ChoicesProps {
    children: ReactNode;
    column?: boolean;
    little?: boolean;
}

export function Choices ({children, little, column}: ChoicesProps) {
    const classes = column ? (little ? "Choices little_column" : "Choices column") : "Choices";
    return (
        <div className={classes}>
            {children}
        </div>
    );
}