import {EmojiPuzzleState} from "./index";
import {
    ADD_EMOJI_PUZZLE_QUESTIONS,
    EMOJI_PUZZLE_CHECK_LINK, EMOJI_PUZZLE_CHECK_STATE,
    EMOJI_PUZZLE_CLUE,
    EMOJI_PUZZLE_SELECT_CHOICE,
    EMOJI_PUZZLE_SKIP_QUESTION
} from "./actionsList";

let emojiPuzzleQuestions: EmojiPuzzleState[] = [];
// const emojiPuzzleQuestions: EmojiPuzzleState[] = [
//     {
//         id: 1,
//         left: ["🥢", "🥦", "🍟"],
//         right: ["🥑", "🍔", "🍚"],
//         links: [
//         ],
//         responses: [
//             {
//                 left: "🥢",
//                 right: "🍚"
//             },
//             {
//                 left: "🥦",
//                 right: "🥑"
//             },
//             {
//                 left: "🍟",
//                 right: "🍔"
//             }
//         ],
//         points: 20,
//         clue: 'Je me mange en combinaison avec mon autre émoji',
//         clueUsed: false,
//     }
// ];

export let emojiPuzzleAnswers = {
    good: 0,
    wrong: 0,
    wrongsOnQuestion: 0
}

const initialState: EmojiPuzzleState = emojiPuzzleQuestions[0];

export let currentEmojiPuzzle = 0;

export const selectFound = (state: any) => state.emojiPuzzle.found;
export const selectQuestion = (state: any) => state.emojiPuzzle;

export const totalQuestionsEmojiPuzzle = () => emojiPuzzleQuestions.length;
export const selectEmojiPuzzlePercent = (state: any) => (currentEmojiPuzzle / totalQuestionsEmojiPuzzle() * 100) || 0;

export function emojiPuzzleReducer (state = initialState, action: any) {
    switch (action.type) {
        case EMOJI_PUZZLE_SELECT_CHOICE:
            return {
                ...state
            };
        case EMOJI_PUZZLE_CHECK_LINK:
            if (!action.payload.left || !action.payload.right) {
                return {
                    ...state
                };
            }
            if (isGoodLink(action.payload.left, action.payload.right) && !alreadyExistingLink(state, action.payload.left, action.payload.right)) {
                emojiPuzzleAnswers.good++;
                return {
                    ...state,
                    links: [...state.links, {
                        left: action.payload.left,
                        right: action.payload.right,
                        from: {x: action.payload.fromX, y: action.payload.fromY},
                        to: {x: action.payload.toX, y: action.payload.toY}
                    }]
                }
            }

            emojiPuzzleAnswers.wrongsOnQuestion++;
            emojiPuzzleAnswers.wrong++;
            return {
                ...state
            };
        case 'EMOJI_REDRAW':
            return {
                ...state
            }
        case EMOJI_PUZZLE_CHECK_STATE:
            if (state.responses.length === state.links.length) {
                currentEmojiPuzzle++;
                emojiPuzzleAnswers.wrongsOnQuestion = 0;
                if (emojiPuzzleQuestions.length > currentEmojiPuzzle)
                    return emojiPuzzleQuestions[currentEmojiPuzzle];
            }
            return {...state};
        case EMOJI_PUZZLE_SKIP_QUESTION:
            currentEmojiPuzzle++;
            emojiPuzzleAnswers.wrongsOnQuestion = 0;
            if (emojiPuzzleQuestions.length > currentEmojiPuzzle)
                return emojiPuzzleQuestions[currentEmojiPuzzle];
            return {
                ...state
            }
        case EMOJI_PUZZLE_CLUE:
            return {
                ...state,
                clueUsed: true
            }
        case ADD_EMOJI_PUZZLE_QUESTIONS:
            if (action.payload && Array.isArray(action.payload)) {
                let arr: EmojiPuzzleState[] = [];
                action.payload.forEach((question: any) => {
                    let left: string[] = [];
                    let right: string[] = [];
                    let responses: any[] = [];

                    let links = question.responses.split(';');
                    for (let i = 0; i < links.length; i++)
                    {
                        if (links[i] !== "")
                        {
                            let lr =  links[i].split('=');
                            left.push(lr[0]);
                            right.push(lr[1]);
                            responses.push({left: lr[0], right: lr[1]});
                        }
                    }

                    let q: EmojiPuzzleState = {
                        id: question.id,
                        left: shuffle(left),
                        right: shuffle(right),
                        links: [],
                        responses: responses,
                        clue: question.clue,
                        clueUsed: false,
                        points: 20,
                    }

                    arr.push(q);
                });
                emojiPuzzleQuestions = arr;
            }

            return emojiPuzzleQuestions[0] || null;
        default:
            return state || null;
    }
}

function isGoodLink (left: string, right: string): boolean {
    for (let i = 0; i < emojiPuzzleQuestions[currentEmojiPuzzle].responses.length; i++)
        if (emojiPuzzleQuestions[currentEmojiPuzzle].responses[i].left === left && emojiPuzzleQuestions[currentEmojiPuzzle].responses[i].right === right) return true;

    return false;
}

function alreadyExistingLink (state: EmojiPuzzleState, left: string, right: string): boolean {
    for (let i = 0; i < state.links.length; i++)
    {
        if (state.links[i].left === left && state.links[i].right === right) return true;
    }

    return false;
}

export function emojiPuzzleTotal () {
    let total = 0;
    for (let i = 0; i < emojiPuzzleQuestions.length; i++) {
        total += emojiPuzzleQuestions[i].points * emojiPuzzleQuestions[i].responses.length;
    }

    return total;
}

export function shuffle(entry: string[]): string[]
{
    for (let i = 0; i < entry.length; i++)
    {
        let index1 = Math.floor(Math.random() * entry.length);
        let index2 = Math.floor(Math.random() * entry.length);

        let temp: string = entry[index1];
        entry[index1] = entry[index2];
        entry[index2] = temp;
    }

    return entry;
}