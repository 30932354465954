import React, {ReactNode} from "react";
import "./PopupDescription.css";

interface PopupDescriptionProps {
    children: ReactNode;
}

export function PopupDescription({children}: PopupDescriptionProps) {
    return (
        <div className={"Popup__Description"}>
            {children}
        </div>
    )
}