import React, {useRef} from "react";
import "./TheListView.css";
import {ViewHeader} from "../View/ViewHeader";
import {ViewContent} from "../View/ViewContent";
import {ViewTitleBar} from "../View/ViewTitleBar";
import {Question} from "../Quiz/Question";
import {TestButton} from "../Buttons/TestButton";
import {motion} from "framer-motion";
import popupIcon from "../../Assets/consigne/La liste.svg";
import {PopupTitle} from "../Popup/PopupTitle";
import {PopupDescription} from "../Popup/PopupDescription";
import {Popup, popupButtonClicked} from "../Popup/Popup";
import {useSelector} from "react-redux";
import {
    currentTheList,
    selectQuestion, theListAnswers,
    totalQuestionsTheList,
} from "../../store/theListReducer";
import { Statement } from "../Quiz/Statement";
import {store, TheListState} from "../../store";
import {ADD_POINTS_ACTION, REMOVE_POINTS_ACTION, selectUser, USE_CLUE_ACTION} from "../../store/userReducer";
import {
    THE_LIST_CHECK_STATE,
    THE_LIST_CLUE,
    THE_LIST_SKIP_QUESTION,
    THE_LIST_VALIDATION,
} from "../../store/actionsList";
import {VictoryView} from "./VictoryView";
import Lottie from "react-lottie";
import trueAnimationData from "../../Assets/test3.json";
import falseAnimationData from "../../Assets/test2.json";
import {TweenMax} from "gsap";
import {gsapAnimationsFrom, gsapAnimationsTo, questionBump} from "../../store/constants";
import {AvatarPopup} from "../Popup/AvatarPopup";
import {ClueButton} from "../Buttons/ClueButton";
import {TextField} from "../TextField";
let stringSimilarity = require('string-similarity');

let animate = false;

export function TheListView () {
    const question = useSelector(selectQuestion);
    // const numberFound = useSelector(selectNumberFound);
    const user = useSelector(selectUser);

    const trueDefaultOptions = {
        loop: false,
        autoplay: true,
        animationData: trueAnimationData,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    }

    const falseDefaultOptions = {
        loop: false,
        autoplay: true,
        animationData: falseAnimationData,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    }

    let elements = Array.from(Array(question.nb_responses).keys());

    const trueAnimation: any = useRef();
    const falseAnimation: any = useRef();

    let lottieStyle: any = {display: "none", pointerEvents: "none", position: "absolute", zIndex: "99", top: '50%', left: '50%', transform: 'translateX(-50%) translateY(-50%)'};

    const animationPlaying = (): boolean => {
        return (trueAnimation.current.anim.currentFrame > 0 && trueAnimation.current.anim.currentFrame < trueAnimation.current.anim.totalFrames - 1) ||
            (falseAnimation.current.anim.currentFrame > 0 && falseAnimation.current.anim.currentFrame < falseAnimation.current.anim.totalFrames - 1);
    }

    const startTrueAnimation = (action: boolean, text: string) => {
        trueAnimation.current.anim.stop();
        trueAnimation.current.anim.play();
        trueAnimation.current.el.style.display = "block";

        let score = {val: user.theListScore};
        let points = question.points + user.theListScore;

        if (action) store.dispatch({type: THE_LIST_VALIDATION, payload: { text: text}});
        TweenMax.to(score, 1.0, {
            val: points,
            roundProps: "val",
            onUpdate: () => {
                let elem = document.querySelector('.View__Profile-information-score');
                if (elem) elem.innerHTML = Math.round(score.val) + " pts";
            },
            onComplete: () => {
                if (isComplete(question)) gsapAnimationsFrom();
                setTimeout(() => {
                    if (action) store.dispatch({type: THE_LIST_CHECK_STATE});
                    store.dispatch({type: ADD_POINTS_ACTION, payload: {score: question.points, type: 'the_list'}});
                    if (isComplete(question)) {
                        questionBump();
                        gsapAnimationsTo();
                    }
                }, 300);
            }
        });

        setTimeout(() => { if (trueAnimation.current) trueAnimation.current.el.style.display = "none"; animate = false;}, 1000);
    }

    const startFalseAnimation = () => {
        falseAnimation.current.el.style.display = "block";
        falseAnimation.current.anim.stop();
        falseAnimation.current.anim.play();

        let score = {val: user.theListScore};
        let points = user.theListScore - 5;
        if (points < 0) points = 0;
        TweenMax.to(score, 1.0, {
            val: points,
            roundProps: "val",
            onUpdate: () => {
                let elem = document.querySelector('.View__Profile-information-score');
                if (elem) elem.innerHTML = Math.round(score.val) + " pts";
            },
            onComplete: () => {
                store.dispatch({type: THE_LIST_VALIDATION, payload: { text: ""}});
                store.dispatch({type: REMOVE_POINTS_ACTION, payload: {points: 5, type: 'the_list'}});
            }
        });
        setTimeout(() => { if (falseAnimation.current) falseAnimation.current.el.style.display = "none"; animate = false;}, 1000);
    }

    const handleKey = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (!animationPlaying() && !animate)
        {
            if(e.key === 'Enter'){
                let element: HTMLInputElement | null = document.querySelector('.TextField');
                if (element) {
                    animate = true;
                    let text = element.value;
                    if (!text) text = "";
                    if (isGood(text)) startTrueAnimation(true, text);
                    else startFalseAnimation();

                    element.value = "";
                }
            }
        }
    }

    if (currentTheList === totalQuestionsTheList()) {
        return (
            <VictoryView type={"the_list"} score={user.theListScore} total={user.total} good={theListAnswers.good} wrong={theListAnswers.wrong} />
        );
    }

    return (
        <motion.div className={"View"} id={"View__the-list"} initial={{ opacity: 0, right: -200, top: 0 }} animate={{ opacity: 1, right: 0, top: 0 }} exit={{ opacity: 0, right: -200, top: 0 }} transition={{ duration: 0.4 }}>
            <ViewHeader>
                <ViewTitleBar type={"the_list"} title={"La liste"} />
                {/*<ViewScoreBar type={"the_list"} />*/}
            </ViewHeader>

            <ViewContent>
                <Question current={currentTheList + 1} max={totalQuestionsTheList()} />

                <div className="answerLine">
                    <Statement>{question.statement}</Statement>
                    <div className="TheList__Words">
                        {
                            elements.map((key: number) => {
                                return (<div className={"TheList__Word"} key={key}>
                                    { question.current.length - 1 >= key ? question.current[key] : "" }
                                </div>)
                            })
                        }
                    </div>
                </div>
                <div>
                    <TextField onKeyPress={handleKey} />
                    <TestButton
                        content='Valider'
                        type={'center'}
                        onClick={() => {
                            if (!animationPlaying() && !animate) {
                                let element: HTMLInputElement | null = document.querySelector('.TextField');
                                if (element) {
                                    let text = element.value;
                                    if (!text) text = "";
                                    animate = true;
                                    if (isGood(text)) startTrueAnimation(true, text);
                                    else startFalseAnimation();
                                    element.value = "";
                                }
                            }
                        }}
                    />
                </div>

                <Lottie ref={trueAnimation} style={lottieStyle} options={trueDefaultOptions} isClickToPauseDisabled width={300} height={300} />
                <Lottie ref={falseAnimation} style={lottieStyle} options={falseDefaultOptions} isClickToPauseDisabled width={300} height={300} />

                { question.helpClicked ? <div style={{textAlign: "center", marginTop: "18px"}}>{question.clue}</div> : "" }

                <div className="View__buttons">
                    { theListAnswers.wrongsOnQuestion >= 0 ? <TestButton
                        content={"> PASSER"}
                        type={"next"}
                        onClick={() => {
                            if (!animationPlaying() && !animate) {
                                gsapAnimationsFrom();
                                setTimeout(() => {
                                    store.dispatch({type: THE_LIST_SKIP_QUESTION});
                                    questionBump();
                                    gsapAnimationsTo();
                                }, 500);
                            }
                        }} /> : '' }
                    <ClueButton
                        content={"Besoin d'un indice ?"}
                        count={user.theListClues}
                        onClick={() => {
                            if (!animationPlaying() && !animate) {
                                if (user.theListClues > 0 && !question.helpClicked) {
                                    store.dispatch({type: USE_CLUE_ACTION, payload: {type: 'the_list'}});
                                    store.dispatch({type: THE_LIST_CLUE, payload: {}});
                                }
                            }
                        }}
                        disabled={question.helpClicked}
                    />
                </div>
            </ViewContent>

            <Popup>
                <img style={{marginBottom: "42px"}} src={popupIcon} alt={"Icon la liste"} />
                <PopupTitle title={"La liste"} />
                <PopupDescription>Tapez les bonnes réponses et validez-les !</PopupDescription>
                <TestButton onClick={popupButtonClicked} content={"OK"} type={"is-large"} />
            </Popup>

            <AvatarPopup />
        </motion.div>
    );
}

function isGood (answer: string) {
    const current = store.getState().theList;

    for (let i = 0; i < current.answers.length; i++)
    {
        if (stringSimilarity.compareTwoStrings(current.answers[i].toLowerCase(), answer.toLowerCase()) > 0.65 && !alreadyFound(current.answers[i])) return true;
    }

    return false;
}

const isComplete = (question: TheListState) => {
    return question.current.length >= question.nb_responses;
}

function alreadyFound (answer: string) {
    const current = store.getState().theList;

    for (let i = 0; i < current.current.length; i++)
    {
        if (current.current[i].toLowerCase() === answer.toLowerCase()) return true;
    }

    return false;
}