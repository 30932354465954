import React from "react";
import "./TextField.css";

interface TextFieldProps {
    onChange?: any;
    onKeyPress?: any;
    onFocus?: any;
    className?: string;
}

export function TextField ({onChange, onKeyPress, className = ""}: TextFieldProps) {
    return (
        <input type="text" className={'TextField ' + className} onFocus={(event) => {
            if (event.target) {
                event.target.scrollIntoView();
            }
        }} onChange={onChange} onKeyPress={onKeyPress} />
    );
}
