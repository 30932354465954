import React from "react";
import "./Question.css";

interface QuestionProps {
    current: number;
    max: number;
}

export function Question({current, max}: QuestionProps) {
    return (
        <div className={"Question"}>
            <strong>Question</strong><div className={"Question__current"}>{current}</div> / {max}
        </div>
    );
}