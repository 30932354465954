import "./CardContent.css";
import React, {ReactNode} from "react";

interface CardContentProps {
    children: ReactNode;
}

export function CardContent ({children}: CardContentProps) {
    return (
        <div className="Card__Content">
            { children }
        </div>
    );
}