import React from "react";
import "./AwardsView.css";
import {motion} from "framer-motion";

import giftBox from "../../Assets/gift-box.svg";
import {Button} from "../Buttons/Button";
import {TextField} from "../TextField";

export function AwardsView() {
    return (
        <motion.div className={"View View__Purple"} id={"View__victory"} style={{padding: "10px 40px"}} initial={{ opacity: 0, right: -200 }} animate={{ opacity: 1, right: 0 }} exit={{ opacity: 0, right: -200 }} transition={{ duration: 0.4 }}>
            <div className={"Victory__Your-score"}>Votre récompense</div>

            <div className="Victory__Animation">
                <img style={{width: "300px", height: "120px"}} src={giftBox} alt={"Cadeau"} />
            </div>

            <h1 style={{fontSize: "36px", lineHeight: "46px", fontWeight: "lighter"}}>Félicitations</h1>
            <h3 style={{fontSize: "20px", marginBottom: 0, lineHeight: "25px", textAlign: "center", fontWeight: "lighter"}}>Vous allez recevoir un bon de réduction de X dans votre établissement préféré.</h3>
            <a style={{fontSize: "12px", marginBottom: 8, marginTop: 16, color: "white"}} href={"/"}>Voir conditions</a>

            <div style={{width: "100%", margin: "16px auto", height: 1, background: "white", opacity: 0.11}} className={"separator"} />

            <div>Vous pouvez recevoir un bon supplménetaire bonus en likant notre page Instagram !</div>
            <Button content={"Nous suivre sur Instagram"} onClick={() => {}} />

            <div style={{width: "100%", margin: "16px auto", height: 1, background: "white", opacity: 0.11}} className={"separator"} />

            <form action="">
                <div className="form-group">
                    <label htmlFor="">Votre email <span>pour recevoir votre bon : </span></label>
                    <TextField />
                </div>

                <Button content={"Valider"} onClick={() => {}} />
            </form>
        </motion.div>
    );
}