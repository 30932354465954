import React from "react";
import "./Emoji.css";

interface EmojiProps {
    name: string;
    image: string;
    selected?: boolean;
    onClick: any;
    onUp?: any;
}

export function Emoji ({image, selected, onClick, onUp}: EmojiProps) {
    let classes = "Emoji";
    if (selected) classes += " Emoji__Selected";
    return (
        <div onMouseDown={onClick} onTouchEnd={onUp} onMouseUp={onUp} className={classes}>
            {image}
        </div>
    )
}
