import React, {ReactNode} from "react";
import "./Right.css";

interface RightProps {
    children: ReactNode;
}

export function Right ({children}: RightProps) {
    return (
        <div className={"EmojiList__Right"}>
            {children}
        </div>
    );
}