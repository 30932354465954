import {UserState} from "./index";
import Avatar from "../Assets/Avatars/Memoji-05.png";

const initialState: UserState = {
    id: 1,
    username: "Akadream",
    account_type: "adults",
    experience_type: "solo",
    missingWordScore: 0,
    missingWordClues: 4,
    hiddenCharacterScore: 0,
    hiddenCharacterClues: 4,
    trueFalseScore: 0,
    trueFalseClues: 5,
    brandQuizScore: 0,
    brandQuizClues: 1,
    theListScore: 0,
    theListClues: 3,
    emojiPuzzleScore: 0,
    emojiPuzzleClues: 1,
    total: 0,
    avatar: Avatar,
};

export const ACCOUNT_TYPES = {
    ADULTS: "adults",
    CHILDREN: "children",
    FAMILY: "family"
}

export const EXPERIENCE_TYPES = {
    SOLO: "solo",
    TEAM: "team"
}

export const ADD_POINTS_ACTION = 'ADD_POINTS_ACTION';
export const ADD_ON_TOTAL_ACTION = 'ADD_ON_TOTAL_ACTION';
export const REMOVE_POINTS_ACTION = 'REMOVE_POINTS_ACTION';
export const REMOVE_SCORE_ACTION = 'REMOVE_SCORE_ACTION';
export const UPDATE_MEMOJI_ACTION = 'UPDATE_MEMOJI_ACTION';
export const UPDATE_ACCOUNT_ACTION = 'UPDATE_ACCOUNT_ACTION';
export const USE_CLUE_ACTION = 'USE_CLUE_ACTION';

export function userReducer (state = initialState, action: any) {
    switch (action.type) {
        case ADD_POINTS_ACTION:
            let score = action.payload.score;
            switch (action.payload.type) {
                case "missing_words":
                    score += state.missingWordScore;
                    return {
                        ...state,
                        missingWordScore: score
                    };
                case "hidden_character":
                    score += state.hiddenCharacterScore;
                    return {
                        ...state,
                        hiddenCharacterScore: score
                    };
                case "the_list":
                    score += state.theListScore;
                    return {
                        ...state,
                        theListScore: score
                    };
                case "brand_quiz":
                    score += state.brandQuizScore;
                    return {
                        ...state,
                        brandQuizScore: score
                    };
                case "true_false":
                    score += state.trueFalseScore;
                    return {
                        ...state,
                        trueFalseScore: score
                    };
                case "emoji_puzzle":
                    score += state.emojiPuzzleScore;
                    return {
                        ...state,
                        emojiPuzzleScore: score
                    };
            }
            return {
                ...state
            };
        case ADD_ON_TOTAL_ACTION:
            switch (action.payload.type) {
                case "missing_words":
                    state.total += state.missingWordScore;
                    break;
                case "hidden_character":
                    state.total += state.hiddenCharacterScore;
                    break;
                case "the_list":
                    state.total += state.theListScore;
                    break;
                case "brand_quiz":
                    state.total += state.brandQuizScore;
                    break;
                case "true_false":
                    state.total += state.trueFalseScore;
                    break;
                case "emoji_puzzle":
                    state.total += state.emojiPuzzleScore;
                    break;
            }
            return state;
        case REMOVE_SCORE_ACTION:
            switch (action.payload.type) {
                case "missing_words":
                    state.missingWordScore = 0;
                    break;
                case "hidden_character":
                    state.hiddenCharacterScore = 0;
                    break;
                case "the_list":
                    state.theListScore = 0;
                    break;
                case "brand_quiz":
                    state.brandQuizScore = 0;
                    break;
                case "true_false":
                    state.trueFalseScore = 0;
                    break;
                case "emoji_puzzle":
                    state.emojiPuzzleScore = 0;
                    break;
            }
            if (action.payload.dont) return state;
            return {...state};
        case REMOVE_POINTS_ACTION:
            let newScore = 0;
            switch (action.payload.type) {
                case "missing_words":
                    newScore = state.missingWordScore - action.payload.points;
                    if (newScore < 0) newScore = 0;
                    return {
                        ...state,
                        missingWordScore: newScore
                    }
                case "hidden_character":
                    newScore = state.hiddenCharacterScore - action.payload.points;
                    if (newScore < 0) newScore = 0;
                    return {
                        ...state,
                        hiddenCharacterScore: newScore
                    }
                case "the_list":
                    newScore = state.theListScore - action.payload.points;
                    if (newScore < 0) newScore = 0;
                    return {
                        ...state,
                        theListScore: newScore
                    }
                case "brand_quiz":
                    newScore = state.brandQuizScore - action.payload.points;
                    if (newScore < 0) newScore = 0;
                    return {
                        ...state,
                        brandQuizScore: newScore
                    }
                case "true_false":
                    newScore = state.trueFalseScore - action.payload.points;
                    if (newScore < 0) newScore = 0;
                    return {
                        ...state,
                        trueFalseScore: newScore
                    }
                case "emoji_puzzle":
                    newScore = state.emojiPuzzleScore - action.payload.points;
                    if (newScore < 0) newScore = 0;
                    return {
                        ...state,
                        emojiPuzzleScore: newScore
                    }
            }
            return state;
        case USE_CLUE_ACTION:
            switch (action.payload.type) {
                case "missing_words":
                    return {
                        ...state,
                        missingWordClues: state.missingWordClues - 1
                    }
                case "hidden_character":
                    return {
                        ...state,
                        hiddenCharacterClues: state.hiddenCharacterClues - 1
                    }
                case "the_list":
                    return {
                        ...state,
                        theListClues: state.theListClues - 1
                    }
                case "brand_quiz":
                    return {
                        ...state,
                        brandQuizClues: state.brandQuizClues - 1
                    }
                case "true_false":
                    return {
                        ...state,
                        trueFalseClues: state.trueFalseClues - 1
                    }
                case "emoji_puzzle":
                    return {
                        ...state,
                        emojiPuzzleClues: state.emojiPuzzleClues - 1
                    }
            }
            return state;
        case UPDATE_MEMOJI_ACTION:
            return {
                ...state,
                avatar: action.payload
            }
        case UPDATE_ACCOUNT_ACTION:
            return {
                ...state,
                username: action.payload.username ?? state.username,
                account_type: action.payload.account_type ?? state.account_type,
                experience_type: action.payload.experience_type ?? state.experience_type
            }
        default:
            return state;
    }
}

export const selectUser = (state: any) => state.user;