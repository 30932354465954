import React, {useRef} from "react";
import "./TrueFalseView.css";
import {ViewHeader} from "../View/ViewHeader";
import {ViewContent} from "../View/ViewContent";
import {ViewTitleBar} from "../View/ViewTitleBar";
import {Question} from "../Quiz/Question";
import {Statement} from "../Quiz/Statement";
import {selectQuestion, totalQuestionsTrueFalse, currentTrueFalse, trueFalseAnswers} from "../../store/trueFalseReducer";
import {VictoryView} from "./VictoryView";
import {motion} from "framer-motion";
import {useSelector} from "react-redux";
import {store, TrueFalseState} from "../../store";
import Lottie from "react-lottie";

import trueAnimationData from "../../Assets/test3.json";
import falseAnimationData from "../../Assets/test2.json";
import {Button} from "../Buttons/Button";
import {Clue} from "../Quiz/Clue";
import gsap, {TweenMax} from "gsap";
import {Popup, popupButtonClicked} from "../Popup/Popup";
import {PopupTitle} from "../Popup/PopupTitle";
import {PopupDescription} from "../Popup/PopupDescription";
import {TestButton} from "../Buttons/TestButton";

import popupIcon from "../../Assets/consigne/vrai ou faux.svg";
import {AvatarPopup} from "../Popup/AvatarPopup";
import {ADD_POINTS_ACTION, REMOVE_POINTS_ACTION, selectUser, USE_CLUE_ACTION} from "../../store/userReducer";
import {
    TRUE_FALSE_CLUE,
    TRUE_FALSE_FALSE,
    TRUE_FALSE_TRUE
} from "../../store/actionsList";
import {gsapAnimationsFrom, gsapAnimationsTo, questionBump} from "../../store/constants";
import {ClueButton} from "../Buttons/ClueButton";

let animate = false;

export function TrueFalseView() {
    const question: TrueFalseState = useSelector(selectQuestion);
    const user = useSelector(selectUser);

    const trueDefaultOptions = {
        loop: false,
        autoplay: true,
        animationData: trueAnimationData,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    }

    const falseDefaultOptions = {
        loop: false,
        autoplay: true,
        animationData: falseAnimationData,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    }

    const trueAnimation: any = useRef();
    const falseAnimation: any = useRef();

    let lottieStyle: any = {display: "none", pointerEvents: "none", position: "absolute", zIndex: "99", top: '50%', left: '50%', transform: 'translateX(-50%) translateY(-50%)'};

    const animationPlaying = (): boolean => {
        return (trueAnimation.current.anim.currentFrame > 0 && trueAnimation.current.anim.currentFrame < trueAnimation.current.anim.totalFrames - 1) ||
            (falseAnimation.current.anim.currentFrame > 0 && falseAnimation.current.anim.currentFrame < falseAnimation.current.anim.totalFrames - 1);
    }

    const startTrueAnimation = (action: boolean) => {
        trueAnimation.current.anim.stop();
        trueAnimation.current.anim.play();
        trueAnimation.current.el.style.display = "block";

        let score = {val: user.trueFalseScore};
        let points = question.points + user.trueFalseScore;

        TweenMax.to(score, 1.0, {
            val: points,
            roundProps: "val",
            onUpdate: () => {
                let elem = document.querySelector('.View__Profile-information-score');
                if (elem) elem.innerHTML = Math.round(score.val) + " pts";
            },
            onComplete: () => {
                gsapAnimationsFrom();
                setTimeout(() => {
                    store.dispatch({type: ADD_POINTS_ACTION, payload: {score: question.points, type: "true_false"}});
                    if (action) store.dispatch({type: TRUE_FALSE_TRUE});
                    else store.dispatch({type: TRUE_FALSE_FALSE});
                    questionBump();
                    gsapAnimationsTo();
                }, 300);
            }
        });

        setTimeout(() => { if (trueAnimation.current) trueAnimation.current.el.style.display = "none"; animate = false;}, 1000);
    }

    const startFalseAnimation = (action: boolean) => {
        falseAnimation.current.el.style.display = "block";
        falseAnimation.current.anim.stop();
        falseAnimation.current.anim.play();

        let score = {val: user.trueFalseScore};
        let points = user.trueFalseScore - 15;
        if (points < 0) points = 0;
        TweenMax.to(score, 1.0, {
            val: points,
            roundProps: "val",
            onUpdate: () => {
                let elem = document.querySelector('.View__Profile-information-score');
                if (elem) elem.innerHTML = Math.round(score.val) + " pts";
            },
            onComplete: () => {
                store.dispatch({type: REMOVE_POINTS_ACTION, payload: {points: 5, type: 'true_false'}});
                gsapAnimationsFrom();
                setTimeout(() => {
                    if (action) store.dispatch({type: TRUE_FALSE_TRUE});
                    else store.dispatch({type: TRUE_FALSE_FALSE});
                    gsapAnimationsTo();
                    questionBump();
                }, 300);
            }
        });

        setTimeout(() => { if (falseAnimation.current) falseAnimation.current.el.style.display = "none"; animate = false;}, 1000);
    }

    let clickedTrue = (question: TrueFalseState) =>
    {
        animate = true;

        if (question.response === 'vrai') {
            gsap.to('.false', {
                opacity: 0,
                duration: 0.2,
            });

            startTrueAnimation(true);
        }
        else {
            gsap.to('.true', {
                opacity: 0,
                duration: 0.2,
            });

            startFalseAnimation(true);
        }
    }

    let clickedFalse = (question: TrueFalseState) =>
    {
        animate = true;

        if (question.response === 'faux') {
            gsap.to('.true', {
                opacity: 0,
                duration: 0.2,
            });

            startTrueAnimation(false);
        }
        else
        {
            gsap.to('.false', {
                opacity: 0,
                duration: 0.2,
            });

            startFalseAnimation(false);
        }
    }

    if (currentTrueFalse === totalQuestionsTrueFalse()) {
        return (
            <VictoryView type={"true_false"} score={user.trueFalseScore} total={user.total} good={trueFalseAnswers.good} wrong={trueFalseAnswers.wrong} />
        );
    }
    else {
        return (
            <motion.div className={"View"} id={"View__true-false"} initial={{ opacity: 0, right: -200, top: 0 }} animate={{ opacity: 1, right: 0, top: 0 }} exit={{ opacity: 0, right: -200, top: 0 }} transition={{ duration: 0.4 }}>
                <ViewHeader>
                    <ViewTitleBar type={"true_false"} title={"Vrai ou faux"} />
                </ViewHeader>

                <ViewContent>
                    <Question current={currentTrueFalse + 1} max={totalQuestionsTrueFalse()} />
                    <motion.div initial={{opacity: 0}} animate={{opacity: 1}} exit={{opacity: 0}} transition={{duration: 1 }}>
                        <Statement>
                            { question.statement }
                        </Statement>
                    </motion.div>

                    <div className="View__buttons bottom_buttons">
                        <Button onClick={() => {if (!animationPlaying() && !animate) { animate = true; clickedTrue(question)}}} content={"Vrai"} type={"true is-large"} />
                        <Button onClick={() => {if (!animationPlaying() && !animate) { animate = true; clickedFalse(question)}}} content={"Faux"} type={"false is-large"} />
                    </div>

                    <Lottie ref={trueAnimation} style={lottieStyle} options={trueDefaultOptions} isClickToPauseDisabled width={300} height={300} />
                    <Lottie ref={falseAnimation} style={lottieStyle} options={falseDefaultOptions} isClickToPauseDisabled width={300} height={300} />

                    { question.clueUsed ? <Clue>{ question.clue }</Clue> : '' }

                    <div className="View__buttons">
                        { (!question.clueUsed) ? <ClueButton count={user.trueFalseClues} onClick={() => {
                            if (user.trueFalseClues > 0) {
                                store.dispatch({type: USE_CLUE_ACTION, payload: {type: 'true_false'}});
                                store.dispatch({type: TRUE_FALSE_CLUE});
                            }
                        }} content={"Besoin d'un indice ?"} /> : "" }
                    </div>
                </ViewContent>

                <Popup>
                    <img style={{marginBottom: "42px"}} src={popupIcon} alt={"Icon vrai ou faux"} />
                    <PopupTitle title={"Vrai ou faux"} />
                    <PopupDescription>Sélectionnez la bonne réponse !</PopupDescription>
                    <TestButton onClick={popupButtonClicked} content={"OK"} type={"is-large"} />
                </Popup>

                <AvatarPopup />
            </motion.div>
        );
    }
}
