import React, {useRef} from "react";
import "./EmojiPuzzleView.css";
import {ViewHeader} from "../View/ViewHeader";
import {ViewTitleBar} from "../View/ViewTitleBar";
import {ViewContent} from "../View/ViewContent";
import {Question} from "../Quiz/Question";
import {motion} from "framer-motion";
import {EmojiList} from "../Quiz/EmojiList";
import {Left} from "../Quiz/Left";
import {Right} from "../Quiz/Right";
import {useSelector} from "react-redux";
import {
    currentEmojiPuzzle, emojiPuzzleAnswers,
    selectQuestion,
    totalQuestionsEmojiPuzzle
} from "../../store/emojiPuzzleReducer";
import {VictoryView} from "./VictoryView";
import {Emoji} from "../Quiz/Emoji";
import {EmojiPuzzleState, store} from "../../store";
import popupIcon from "../../Assets/consigne/emoji puzzle.svg";
import {PopupTitle} from "../Popup/PopupTitle";
import {PopupDescription} from "../Popup/PopupDescription";
import {TestButton} from "../Buttons/TestButton";
import {Popup, popupButtonClicked} from "../Popup/Popup";
import {
    EMOJI_PUZZLE_CHECK_LINK, EMOJI_PUZZLE_CHECK_STATE, EMOJI_PUZZLE_CLUE,
    EMOJI_PUZZLE_SKIP_QUESTION
} from "../../store/actionsList";
import {TweenMax} from "gsap";
import Lottie from "react-lottie";
import {
    falseDefaultOptions,
    gsapAnimationsFrom,
    gsapAnimationsTo,
    questionBump,
    trueDefaultOptions
} from "../../store/constants";
import {ADD_POINTS_ACTION, REMOVE_POINTS_ACTION, selectUser, USE_CLUE_ACTION} from "../../store/userReducer";
import {Clue} from "../Quiz/Clue";
import {AvatarPopup} from "../Popup/AvatarPopup";
import { ClueButton } from "../Buttons/ClueButton";

let from: any = {
    x: 0,
    y: 0
}

let to: any = {
    x: 0,
    y: 0
}

let currentLeft: string = '';
let currentRight: string = '';

let offsetX = 0;
let offsetY = 0;

let animate = false;

export function EmojiPuzzleView () {
    const question = useSelector(selectQuestion);
    const user = useSelector(selectUser);
    let elem = document.querySelector('.View');

    if (elem) {
        offsetX = elem.clientLeft;
        offsetY = elem.clientTop;
    }

    const trueAnimation: any = useRef();
    const falseAnimation: any = useRef();

    let lottieStyle: any = {display: "none", pointerEvents: "none", position: "absolute", zIndex: "99", top: '50%', left: '50%', transform: 'translateX(-50%) translateY(-50%)'};

    const animationPlaying = (): boolean => {
        return (trueAnimation.current.anim.currentFrame > 0 && trueAnimation.current.anim.currentFrame < trueAnimation.current.anim.totalFrames - 1) ||
            (falseAnimation.current.anim.currentFrame > 0 && falseAnimation.current.anim.currentFrame < falseAnimation.current.anim.totalFrames - 1);
    }

    function clickedLeftEmoji(event: any) {
        if (isFound(event.target.innerText) || animationPlaying() || animate) return;
        from.x = event.target.offsetLeft + event.target.offsetParent.offsetLeft + event.target.offsetParent.offsetParent.offsetLeft + 85;
        from.y = event.target.offsetTop + event.target.offsetParent.offsetTop + event.target.offsetParent.offsetParent.offsetTop + 86 / 2;

        currentLeft = event.target.innerText;

        store.dispatch({type: 'EMOJI_REDRAW'});
        if (currentRight !== "" && currentLeft !== "") {
            animate = true;
            if (isGood(currentLeft, currentRight)) startTrueAnimation(currentLeft, currentRight);
            else startFalseAnimation();
        }
    }

    function clickedRightEmoji(event: any) {
        console.log(event);
        if (isFound(event.target.innerText) || animationPlaying() || animate) return;
        to.x = event.target.offsetLeft + event.target.offsetParent.offsetLeft + event.target.offsetParent.offsetParent.offsetLeft;
        to.y = event.target.offsetTop + event.target.offsetParent.offsetTop + event.target.offsetParent.offsetParent.offsetTop + 86 / 2;
        currentRight = event.target.innerText;

        store.dispatch({type: 'EMOJI_REDRAW'});
        if (currentRight !== "" && currentLeft !== "") {
            animate = true;
            if (isGood(currentLeft, currentRight)) startTrueAnimation(currentLeft, currentRight);
            else startFalseAnimation();
        }
    }

    const startTrueAnimation = (left: string, right: string) => {
        trueAnimation.current.anim.stop();
        trueAnimation.current.anim.play();
        trueAnimation.current.el.style.display = "block";

        let score = {val: user.emojiPuzzleScore};
        let points = question.points + user.emojiPuzzleScore;

        store.dispatch({type: EMOJI_PUZZLE_CHECK_LINK, payload: {left: left, right: right, fromX: from.x, fromY: from.y, toX: to.x, toY: to.y}});
        TweenMax.to(score, 1.0, {
            val: points,
            roundProps: "val",
            onUpdate: () => {
                let elem = document.querySelector('.View__Profile-information-score');
                if (elem) elem.innerHTML = Math.round(score.val) + " pts";
            },
            onComplete: () => {
                if (isComplete(question)) gsapAnimationsFrom();
                setTimeout(() => {
                    store.dispatch({type: ADD_POINTS_ACTION, payload: {score: question.points, type: "emoji_puzzle"}});
                    store.dispatch({type: EMOJI_PUZZLE_CHECK_STATE});
                    if (isComplete(question)) {
                        questionBump();
                        gsapAnimationsTo();
                    }

                }, 300);
                currentLeft = currentRight = "";
            }
        });

        setTimeout(() => { if (trueAnimation.current) trueAnimation.current.el.style.display = "none"; animate = false;}, 1000);
    }

    const startFalseAnimation = () => {
        falseAnimation.current.el.style.display = "block";
        falseAnimation.current.anim.stop();
        falseAnimation.current.anim.play();

        let score = {val: user.emojiPuzzleScore};
        let points = user.emojiPuzzleScore - 5;
        if (points < 0) points = 0;
        TweenMax.to(score, 1.0, {
            val: points,
            roundProps: "val",
            onUpdate: () => {
                let elem = document.querySelector('.View__Profile-information-score');
                if (elem) elem.innerHTML = Math.round(score.val) + " pts";
            },
            onComplete: () => {
                store.dispatch({type: EMOJI_PUZZLE_CHECK_LINK, payload: {left: "a", right: "z", fromX: from.x, fromY: from.y, toX: to.x, toY: to.y}});
                store.dispatch({type: REMOVE_POINTS_ACTION, payload: {points: 5, type: 'emoji_puzzle'}});
                if (isComplete(question)) gsapAnimationsFrom();
                setTimeout(() => {
                    if (isComplete(question)) {
                        questionBump();
                        gsapAnimationsTo();
                    }
                }, 300);
            }
        });


        currentLeft = currentRight = "";
        setTimeout(() => { if (falseAnimation.current) falseAnimation.current.el.style.display = "none"; animate = false;}, 1000);
    }

    if (currentEmojiPuzzle === totalQuestionsEmojiPuzzle()) {
        return (
            <VictoryView type={"emoji_puzzle"} score={user.emojiPuzzleScore} total={user.total} good={emojiPuzzleAnswers.good} wrong={emojiPuzzleAnswers.wrong} />
        );
    }
    else {
        return (
            <div className={"fill"} onTouchMove={() => {store.dispatch({type: 'EMOJI_REDRAW'});}}>
                <motion.div className={"View"} id={"View__Emoji-puzzle"} initial={{opacity: 0, right: -200, top: 0}}
                            animate={{opacity: 1, right: 0, top: 0}} exit={{opacity: 0, right: -200, top: 0}}
                            transition={{duration: 0.4}}>
                    <ViewHeader>
                        <ViewTitleBar type={"emoji_puzzle"} title={"Emoji puzzle"}/>
                        {/*<ViewScoreBar type={"emoji_puzzle"} />*/}
                    </ViewHeader>

                    <ViewContent>
                        <div className={"fill"}>
                            <Question current={currentEmojiPuzzle + 1} max={totalQuestionsEmojiPuzzle()}/>
                            <EmojiList>
                                <Left>
                                    {
                                        question.left.map((emoji: string, k: number) => {
                                            if (emoji === currentLeft || isFound(emoji)) {
                                                return <Emoji selected name={emoji} image={emoji} key={k} onClick={clickedLeftEmoji} />
                                            }
                                            return <Emoji name={emoji} image={emoji} key={k} onClick={clickedLeftEmoji} />
                                        })
                                    }
                                </Left>

                                <Right>
                                    {
                                        question.right.map((emoji: string, k: number) => {
                                            if (emoji === currentRight || isFound(emoji)) return <Emoji selected name={emoji} image={emoji} key={k} onClick={clickedRightEmoji} />
                                            return <Emoji name={emoji} image={emoji} key={k} onClick={clickedRightEmoji} />
                                        })
                                    }
                                </Right>
                            </EmojiList>
                        </div>

                        <Lottie ref={trueAnimation} style={lottieStyle} options={trueDefaultOptions} isClickToPauseDisabled width={300} height={300} />
                        <Lottie ref={falseAnimation} style={lottieStyle} options={falseDefaultOptions} isClickToPauseDisabled width={300} height={300} />

                        { question.clueUsed ? <Clue>{ question.clue }</Clue> : '' }

                        <div className="View__buttons">
                            { emojiPuzzleAnswers.wrongsOnQuestion >= 0 ? <TestButton
                                content={"> PASSER"}
                                onClick={() => {
                                    gsapAnimationsFrom();
                                    setTimeout(() => {
                                        store.dispatch({type: EMOJI_PUZZLE_SKIP_QUESTION});
                                        questionBump();
                                        gsapAnimationsTo();
                                    }, 500);
                                }}
                                type={"next"} /> : '' }
                            { (!question.clueUsed) ? <ClueButton onClick={() => {
                                if (user.emojiPuzzleClues > 0) {
                                    store.dispatch({type: USE_CLUE_ACTION, payload: {type: 'emoji_puzzle'}});
                                    store.dispatch({type: EMOJI_PUZZLE_CLUE});
                                }
                            }} count={user.emojiPuzzleClues} content={"Besoin d'un indice ?"} /> : "" }
                        </div>
                    </ViewContent>

                    <Popup>
                        <img style={{marginBottom: "42px"}} src={popupIcon} alt={"Icon emoji puzzle"} />
                        <PopupTitle title={"Emoji puzzle"} />
                        <PopupDescription>Cliquer d'abord sur un emoji puis cliquer sur l'emoji en relation avec celui sélectionné</PopupDescription>
                        <TestButton onClick={popupButtonClicked} content={"OK"} type={"is-large"} />
                    </Popup>

                    <AvatarPopup />

                    <svg className={"Emoji__Lines"}>
                        {
                            question.links.map((link: any, k: number) => {
                                return <line key={k} x1={link.from.x - offsetX} y1={link.from.y - offsetY} x2={link.to.x - offsetX} y2={link.to.y - offsetY} className={"Emoji__Line"} />
                            })
                        }
                    </svg>
                </motion.div>
            </div>
        );
    }
}

function isGood(left: string, right: string) {
    const state = store.getState().emojiPuzzle;
    for (let i = 0; i < state.responses.length; i++) {
        if (state.responses[i].left === left && state.responses[i].right === right) return true;
    }
    return false;
}

function isFound(emoji: string) {
    const state = store.getState().emojiPuzzle;
    for (let i = 0; i < state.links.length; i++) {
        if (state.links[i].left === emoji || state.links[i].right === emoji) return true;
    }
    return false;
}

window.addEventListener('DOMContentLoaded', () => {
    let elem = document.querySelector('.View');
    if (elem) {
        elem.addEventListener('scroll', () => {
            store.dispatch({type: 'EMOJI_REDRAW'});
        });
    }
});

const isComplete = (question: EmojiPuzzleState): boolean => {
    return question.responses.length === question.links.length;
}
