import React, {ReactNode} from "react";
import "./Statement.css";

interface StatementProps {
    children: ReactNode;
}

export function Statement({children}: StatementProps) {
    return (
        <div className={"Statement"}>
            {children}
        </div>
    );
}