import "./Score.css";

interface ScoreProps {
    value: number;
    max: number;
}

export function Score ({value, max}: ScoreProps) {
    return (
        <div className="Score">
            <div className="Score__Label">Votre score</div>
            <div className="Score__value">{value}/{max}</div>
        </div>
    );
}