import "./EndView.css";
import React from "react";
import {motion} from "framer-motion";
import {ProgressBar} from "../ProgressBar/ProgressBar";
import {IconButton} from "../Buttons/IconButton";

import icon from "../../Assets/gift-box.svg";
import Lottie from "react-lottie";
import animationData from "../../Assets/confetti.json";

export function EndView () {
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    };

    return (
        <motion.div className={"View View__Purple"} id={"View__end"} initial={{ opacity: 0, right: -200 }} animate={{ opacity: 1, right: 0 }} exit={{ opacity: 0, right: -200 }} transition={{ duration: 0.4 }}>
            {/*<img src={congratsImage} draggable={false} style={{width: '100%'}} alt={"Félicitation"} />*/}
            <Lottie options={defaultOptions} height={250} isClickToPauseDisabled />

            <div className="View__content">
                <div className={"End__Congrats"}>Félicitations !</div>
                <div className={"End__Score"}>Vous avez acquis 13 543 pts sur les 15 000 possibles.</div>

                <ProgressBar value={80} isLarge />

                <div className={"End__Won"}>Vous avez battu <span className={"End__percent"}>80 %</span> des joueurs !</div>
                <div className={"End__Won"}>Vous méritez « XXX » à votre nom</div>

                <IconButton path={"/home"} icon={icon} content={"Découvrez votre cadeau"} />
            </div>
        </motion.div>
    );
}
