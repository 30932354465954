import './Button.css';
import React from 'react';
import {Link} from "react-router-dom";

interface ButtonLinkProps {
    content: string;
    link: string;
    type?: string;
}

export function ButtonLink ({content, link, type}: ButtonLinkProps) {
    return (
        <Link style={{width: "200px"}} to={link} className={'Button ' + type}>{ content }</Link>
    );
}