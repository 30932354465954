import {combineReducers, createStore} from "redux";
import {trueFalseReducer} from "./trueFalseReducer";
import {missingWordsReducer} from "./missingWordsReduce";
import {hiddenCharacterReducer} from "./hiddenCharacterReducer";
import {brandQuizReducer} from "./brandQuizReducer";
import {emojiPuzzleReducer} from "./emojiPuzzleReducer";
import {userReducer} from "./userReducer";
import {theListReducer} from "./theListReducer";

export const rootReducer = combineReducers({
    user: userReducer,
    trueFalse: trueFalseReducer,
    missingWords: missingWordsReducer,
    theList: theListReducer,
    hiddenCharacter: hiddenCharacterReducer,
    brandQuiz: brandQuizReducer,
    emojiPuzzle: emojiPuzzleReducer
});
export const store = createStore(rootReducer, (window as any).__REDUX_DEVTOOLS_EXTENSION__ && (window as any).__REDUX_DEVTOOLS_EXTENSION__());

export interface CounterState {
    counter: number;
}

export interface TrueFalseState {
    id: number;
    statement: string;
    response: string;
    points: number;
    found: boolean;
    clue: string;
    clueUsed: boolean;
}

export interface UserState {
    id: number;
    username: string;
    account_type: string;
    experience_type: string;
    missingWordScore: number;
    missingWordClues: number;
    hiddenCharacterScore: number;
    hiddenCharacterClues: number;
    theListScore: number;
    theListClues: number;
    trueFalseScore: number;
    trueFalseClues: number;
    brandQuizScore: number;
    brandQuizClues: number;
    emojiPuzzleScore: number;
    emojiPuzzleClues: number;
    total: number;
    avatar: any;
}

export interface MissingWordsState {
    id: number;
    statement: string;
    lines: string[];
    choices: string[];
    responses: any[];
    goods: string[];
    disabled: string[];
    wrongs: string[];
    points: number;
    cluesUsed: boolean;
}

export interface HiddenCharacterState {
    id: number;
    statement: string;
    choices: string[];
    revealed: string[];
    response: string;
    points: number;
    cluesUsed: boolean;
}

export interface BrandQuizState {
    id: number;
    statement: string;
    choices: string[];
    responses: string[];
    found: string[];
    wrongs: string[];
    disabled: string[];
    points: number;
    cluesUsed: boolean;
}

export interface EmojiPuzzleState {
    id: number;
    left: string[];
    right: string[];
    links: any[];
    responses: any[];
    points: number;
    clue: string;
    clueUsed: boolean;
}

export interface TheListState {
    id: number;
    statement: string;
    answers: string[];
    current: string[];
    nb_responses: number;
    clue: string;
    helpClicked: boolean;
    points: number;
}
