import './TestButton.css';

interface TestButtonProps {
    content: string;
    type?: string;
    onClick?: any;
    disabled?: boolean;
}

export function TestButton ({content, type, onClick, disabled}: TestButtonProps) {
    return (
        <button onClick={onClick} className={'Button ' + type}>{ content }</button>
    );
}