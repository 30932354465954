import "./Progress.css";
import React from "react";

interface ProgressProps {
    value: number;
    isLarge?: boolean;
}

export function Progress ({value, isLarge}: ProgressProps) {
    const style = value < 15 ? {left: "8px"} : {left: "auto"};
    const label = value > 0 ? <span style={style} className="ProgressLabel">{value}%</span> : <span style={style} className="ProgressLabel empty">{value}%</span>;

    if (isLarge) {
        return (
            <div className="Progress Progress__Large" style={{ width: value + '%' }}>
                {label}
            </div>
        );
    }

    return (
        <div className="Progress" style={{ width: value + '%' }}>
            {label}
        </div>
    );
}