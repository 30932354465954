import "./Popup.css";
import React, {ReactNode} from "react";
import {motion} from "framer-motion";
import gsap from "gsap";

interface PopupProps {
    children: ReactNode,
    large?: boolean,
    none?: boolean,
    spaced?: boolean,
}

export function Popup ({children, none, spaced, large}: PopupProps) {
    let classes = "Popup";
    if (large) classes += " Popup__large";
    if (none) classes += " none";
    if (spaced) classes += " spaced";
    return (
        <div className={classes}>
            <motion.div className="Popup__Mask" initial={{bottom: -1000}} animate={{bottom: 0}} exit={{bottom: -1000}} transition={{duration: 0.5}}>
                { children }
            </motion.div>
        </div>
    );
}

export function popupButtonClicked () {
    gsap.to('.Popup__Mask', {
        y: 1000,
        duration: 0.5
    });
    gsap.to('.Popup', {
        opacity: 0,
        duration: 0.5
    });
    setTimeout(() => {
        let element = document.querySelector('.Popup');
        if (element) element.classList.add('none');
    }, 500);
}
