import './Button.css';
import React from 'react';

interface ButtonProps {
    content: string;
    onClick: any;
    type?: string;
}

export function Button ({content, onClick, type}: ButtonProps) {
    return (
        <button onClick={onClick} className={'Button ' + type}>{ content }</button>
    );
}