import React, {ReactNode} from "react";
import "./Text.css";

interface TextProps {
    children: ReactNode
}

export function Text ({ children }: TextProps) {
    return (
        <div className={"Text"}>
            { children }
        </div>
    );
}