import React from "react";
import { Link } from "react-router-dom";
import "./IconButton.css";

interface IconButtonProps {
    icon: any,
    path: string,
    content: string,
}

export function IconButton ({icon, path, content}: IconButtonProps) {
    return (
        <Link to={path} className={"IconButton"}>
            <img src={icon} alt={"Icone"} className={"IconButton__Icon"} />
            <div className={"IconButton__Content"}>{content}</div>
        </Link>
    );
}
